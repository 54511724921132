import { useState, useEffect, useRef } from 'react';
import { differenceInMilliseconds, addDays } from 'date-fns';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { metalFixingInstrumentsSelector } from '~selectors/instrumentsSelector';
import { metalsFixingMarketsStatusesSelector } from '~selectors/metalsFixingMarketStatusesSelector';
import orderSettingsSelector from '~selectors/orderSettingsSelector';
import dateTime from '~utils/date-time';
import dateFromServer from '~utils/dateFromServer';
import {
  marketClosureDateTimes,
  isClosed,
  reOpenAt,
} from '~utils/metalsFixing';

import {
  ClosureStatuses,
  ClosureDateTimes,
  DelayClosureStatus,
  Instrument,
} from '~types/common';
import { reduxMetalsFixingMarketStatusUpdate } from '~actions/ui';
import useDocumentVisibility from './useDocumentVisibility';

const useMetalsFixing = () => {
  const [reCheckAt, setReCheckAt] = useState(''); // tab / page / window is visible

  const {
    orderTypesAllowed: { FIXING: isFxing } = {},
    metalsFixingMarketOverride,
    marketClosingTime,
    marketClosedTime,
  } = useSelector(orderSettingsSelector);

  const metalFixingInstruments = useSelector(metalFixingInstrumentsSelector);

  const closeStatuses: ClosureStatuses = useSelector(
    metalsFixingMarketsStatusesSelector
  );

  const timer = useRef<ReturnType<typeof setTimeout>>();
  const dispatch = useDispatch();
  const isDocumentVisible = useDocumentVisibility(
    isFxing && metalFixingInstruments.length
  );

  // recheck when page is changed from hidden to visible
  useEffect(() => {
    if (isFxing && metalFixingInstruments.length && isDocumentVisible) {
      setReCheckAt(new Date().toISOString());
    }
  }, [isFxing, metalFixingInstruments.length, isDocumentVisible]);

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }

    // only run  => fixing order allowed + metals fixing instruments enabled
    if (
      !metalsFixingMarketOverride &&
      isFxing &&
      metalFixingInstruments.length
    ) {
      const strDate = dateFromServer(window.location.origin);
      const date =
        typeof strDate === 'string'
          ? new Date(strDate)
          : zonedTimeToUtc(new Date(), dateTime.getTimeZoneValue());

      const isoStr = date.toISOString(); // 2024-08-28T04:04:02.000Z
      const [ymd] = isoStr.split('T');

      const closureDTs: ClosureDateTimes = marketClosureDateTimes(
        ymd,
        metalFixingInstruments
      ); // same day close time

      if (!isEmpty(closureDTs)) {
        const statusesUpdatedNow: ClosureStatuses = {};
        let statusesUpdatedDelay: DelayClosureStatus = null;

        Object.keys(closureDTs).forEach((inst) => {
          const closureDT = closureDTs[inst];
          const closeStatus = closeStatuses[inst];

          if (isClosed(date, closureDT) && closeStatus !== 'closed') {
            statusesUpdatedNow[inst] = 'closed';
          } else {
            let diff = differenceInMilliseconds(closureDT, date);

            // next day => closed
            if (diff < 0) {
              diff = differenceInMilliseconds(addDays(closureDT, 1), date);
            }

            // open
            if (!closeStatus) {
              // open => closing
              const closingTimes = marketClosingTime * 60 * 1000; // 20 mins before cut off
              if (diff < closingTimes) {
                statusesUpdatedNow[inst] = 'closing';
              } else if (
                !statusesUpdatedDelay ||
                statusesUpdatedDelay.delay > diff - closingTimes
              ) {
                statusesUpdatedDelay = {
                  id: inst,
                  delay: diff - closingTimes,
                  status: 'closing',
                };
              }
            }

            // closing
            // open, closing
            if (!closeStatus || closeStatus === 'closing') {
              // open => closing
              const cutOffTimes = marketClosedTime * 60 * 1000; // 20 mins before cut off
              if (diff < cutOffTimes) {
                statusesUpdatedNow[inst] = 'closed';
              } else if (
                !statusesUpdatedDelay ||
                statusesUpdatedDelay.delay > diff - cutOffTimes
              ) {
                statusesUpdatedDelay = {
                  id: inst,
                  delay: diff - cutOffTimes,
                  status: 'closed',
                };
              }
            }

            // closed
            if (closeStatus === 'closed') {
              const reOpenDelay = reOpenAt(date);

              if (
                !statusesUpdatedDelay ||
                statusesUpdatedDelay.delay > reOpenDelay
              ) {
                statusesUpdatedDelay = {
                  id: inst,
                  delay: reOpenDelay,
                  status: null,
                };
              }
            }
          }
        });

        // priority => updated status now
        if (!isEmpty(statusesUpdatedNow)) {
          dispatch(
            reduxMetalsFixingMarketStatusUpdate({ ...statusesUpdatedNow })
          );
        } else if (statusesUpdatedDelay) {
          // low priority => updated status delay
          timer.current = setTimeout(() => {
            // recently next event
            const payload = {
              [statusesUpdatedDelay.id]: statusesUpdatedDelay.status,
            };

            // status is null, re-open all at 5pm NY
            if (!statusesUpdatedDelay.status) {
              metalFixingInstruments.forEach((inst: Instrument) => {
                payload[inst.instrument_id] = statusesUpdatedDelay.status;
              });
            }

            dispatch(reduxMetalsFixingMarketStatusUpdate(payload));
          }, statusesUpdatedDelay.delay);
        }
      }
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [
    metalsFixingMarketOverride,
    isFxing,
    metalFixingInstruments,
    marketClosingTime,
    marketClosedTime,
    closeStatuses,
    dispatch,
    reCheckAt,
  ]);
};

export default useMetalsFixing;
