const tradingWidgetDefaultSettings = {
  doubleClickOrder: true,
  showPeggOnTop: true,
  showSource: false,
  termCurrency: false,
  tradableLevels: true,
  ocoAutomatic: false,
  use_only_input_amount: false,
  selectedView: 0,
  amount: '1,000,000',
  baseAmount: '1,000,000',
  termAmount: '1,000,000',
  sub_widgets: {},
};

export default tradingWidgetDefaultSettings;
