import { orders as l } from '~configs/language';
import { Language } from '~types/common';

const { order_modal: orderModal } = l;

// 17801 USD/KRW NDF NDMAR
// 17802 USD/KRW NDF TOMMAR
// 17878 USD/KRW FX SPOTMAR
// 17879 USD/KRW FX TOMSPOTMAR
export const marInstruments = [17801, 17802, 17878, 17879];

export const isMARInstrument = (instrumentId: string | number) =>
  marInstruments.includes(Number(instrumentId));

// IPS-150, IPS-149 and IPS-158 ( Exchange Rate to Forward Points )
export const ipsRestOrderPriceCustomLabel = (
  orderType: string,
  name: string,
  lang: Language,
  instrumentId: string | number,
  labelText: string
): string => {
  if (
    name === 'price' &&
    ['LIMIT', 'MAN_OFFSET', 'SLICER', 'ICEBERG'].includes(orderType) &&
    isMARInstrument(instrumentId)
  ) {
    return orderModal.price.label_price_mar[lang];
  }

  return labelText;
};

// IPS-132, IPS-158 (disable the Price Range validation)
export const skipPriceRangeValidate = (instrumentId: string | number) =>
  isMARInstrument(instrumentId);

// IPS-147, IPS-158
// Must show the + sign on positive numbers for MAR Instruments
export const askBidPositiveSign = (
  instrumentId: string | number,
  price: string
) => (isMARInstrument(instrumentId) && price && Number(price) > 0 ? `+` : '');

export const priceAlignStyle = (
  instrumentId: string | number,
  price: string
) => ({
  alignSelf: price === '---' ? 'center' : 'baseline',
  ...(isMARInstrument(instrumentId) && price && price !== '---'
    ? { fontSize: '2.8em' }
    : {}),
});
