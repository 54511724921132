import { EventEmitter } from 'events';
import cuid from 'cuid';

const Constants = {
  CHANGE: 'change',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

type NotificationType = (typeof Constants)[keyof typeof Constants];

interface NotifyOptions {
  id?: string;
  type?: NotificationType;
  message: string;
  title?: string | null;
  timeOut?: number;
  closeButton?: boolean;
  onClick?: () => void;
}

interface Notification extends Required<Omit<NotifyOptions, 'id'>> {
  id: string;
}

class NotificationManager extends EventEmitter {
  private listNotify: Notification[] = [];

  create(notify: NotifyOptions): void {
    const defaultNotify: NotifyOptions = {
      type: 'info',
      title: null,
      message: null,
      timeOut: 5000,
    };

    this.listNotify = [
      ...this.listNotify,
      {
        ...defaultNotify,
        ...notify,
        id: notify.id ?? cuid(),
      } as Notification,
    ];

    this.emitChange();
  }

  info(
    message: string,
    title?: string,
    timeOut?: number,
    id?: string,
    closeButton?: boolean,
    onClick?: () => void
  ) {
    this.create({
      id,
      type: Constants.INFO,
      message,
      title,
      timeOut,
      onClick,
      closeButton,
    });
  }

  success(
    message: string,
    title?: string,
    timeOut?: number,
    id?: string,
    closeButton?: boolean,
    onClick?: () => void
  ) {
    this.create({
      id,
      type: Constants.SUCCESS,
      message,
      title,
      timeOut,
      onClick,
      closeButton,
    });
  }

  warning(
    message: string,
    title?: string,
    timeOut?: number,
    id?: string,
    closeButton?: boolean,
    onClick?: () => void
  ) {
    this.create({
      id,
      type: Constants.WARNING,
      message,
      title,
      timeOut,
      onClick,
      closeButton,
    });
  }

  error(
    message: string,
    title?: string,
    timeOut?: number,
    id?: string,
    closeButton?: boolean,
    onClick?: () => void
  ) {
    this.create({
      id,
      type: Constants.ERROR,
      message,
      title,
      timeOut,
      onClick,
      closeButton,
    });
  }

  remove(id: string) {
    this.listNotify = this.listNotify.filter((n) => id !== n.id);
    this.emitChange();
  }

  removeAll() {
    this.listNotify.length = 0;
    this.emitChange();
  }

  emitChange() {
    this.emit(Constants.CHANGE, this.listNotify);
  }

  addChangeListener(callback: (notifications: Notification[]) => void) {
    this.addListener(Constants.CHANGE, callback);
  }

  removeChangeListener(callback: (notifications: Notification[]) => void) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new NotificationManager();
