export const common = {
  buy: {
    en: 'Buy ',
    es: 'Compra ',
    es_sales: 'B Venta',
    po: 'Compra ',
  },
  sell: {
    en: 'Sell ',
    es: 'Venta ',
    es_sales: 'B Compra ',
    po: 'Venda ',
  },
  buyOrSell: {
    en: 'Buy or Sell',
    es: 'Compra o Venta',
    es_sales: 'B Venta o B Compra',
    po: 'Compra o Venda',
  },
  market: {
    en: 'Market',
    es: 'Mercado',
    es_sales: 'Mercado',
    po: 'Mercado',
  },
  limit: {
    en: 'Limit',
    es: 'Límite',
    es_sales: 'Límite',
    po: 'Limite',
  },
  order_type: {
    en: 'Order type',
    es: 'Tipo de orden',
    es_sales: 'Tipo de orden',
    po: 'Tipo de ordem',
  },
  button_confirm: {
    en: 'Confirm',
    es: 'Confirmar',
    es_sales: 'Confirmar',
    po: 'Confirmar',
  },
  button_cancel: {
    en: 'Cancel',
    es: 'Cancelar',
    es_sales: 'Cancelar',
    po: 'Cancelar',
  },
  label_expiration: {
    en: 'Fill or Kill',
    es: 'Todo o Nada',
    es_sales: 'Todo o Nada',
    po: 'Fill or Kill (FOK)',
  },
  pair: {
    en: 'Pair',
    es: 'Par',
    es_sales: 'Par',
    po: 'Par',
  },
  unrealized: {
    en: 'Unrealized',
    es: 'Utilidad por Realizar',
    es_sales: 'Utilidad por Realizar',
    po: 'Lucro por realizar',
  },
  realized: {
    en: 'Realized',
    es: 'Utilidad Realizada',
    es_sales: 'Utilidad Realizada',
    po: 'Lucro realizado',
  },
  current_position: {
    en: 'Current Position',
    es: 'Posición Actual',
    es_sales: 'Posición Actual',
    po: 'Posição Atual',
  },
  not_enabled_widget: {
    en: 'Widget not enabled',
    es: 'Panel no habilitado',
    es_sales: 'Panel no habilitado',
    po: 'Painel inactivo',
  },
  not_enabled_order: {
    en: 'Order Type not enabled',
    es: 'Tipo de Orden no habilitado',
    es_sales: 'Tipo de Orden no habilitado',
    po: 'Tipo de Ordem inactivo',
  },
  select_instrument_widget: {
    en: 'Select an instrument...',
    es: 'Selecciona un instrumento...',
    es_sales: 'Selecciona un instrumento...',
    po: 'Selecione um instrumento...',
  },
  loading_widget: {
    en: 'Loading widget',
    es: 'Cargando Panel',
    es_sales: 'Cargando Panel',
    po: 'Carregando Painel',
  },
  error_widget: {
    en: 'An error occurred loading the widget',
    es: 'Un error se ha producido al cargar el Panel',
    es_sales: 'Un error se ha producido al cargar el Panel',
    po: 'Ocorreu um erro ao carregar o painel',
  },

  save_settings: {
    en: 'Save changes',
    es: 'Guardar cambios',
    es_sales: 'Guardar cambios',
    po: 'Salvar Alterações',
  },
  settings: {
    en: 'Settings',
    es: 'Configuración',
    es_sales: 'Configuración',
    po: 'CONFIGURAÇÃO',
  },
  orderStatus: {
    ACTIVE: {
      en: 'ACTIVE',
      es: 'ACTIVA',
      es_sales: 'ACTIVA',
      po: 'ATIVO',
    },
    PENDING_NEW: {
      en: 'PENDING',
      es: 'PENDIENTE',
      es_sales: 'PENDIENTE',
      po: 'PENDING',
    },
    CANCELED: {
      en: 'CANCELED',
      es: 'CANCELADO',
      es_sales: 'CANCELADO',
      po: 'CANCELADO',
    },
    PARTIAL: {
      en: 'PARTIALLY FILLED',
      es: 'LLENADO PARCIAL',
      es_sales: 'LLENADO PARCIAL',
      po: 'PARCIALMENTE PREENCHIDO',
    },
    FILLED: {
      en: 'FILLED',
      es: 'COMPLETADO',
      es_sales: 'COMPLETADO',
      po: 'PREENCHIDO',
    },
    REJECTED: {
      en: 'REJECTED',
      es: 'RECHAZADO',
      es_sales: 'RECHAZADO',
      po: 'REJEITADO',
    },
    NEW: {
      en: 'ACTIVE',
      es: 'ACTIVA',
      es_sales: 'ACTIVA',
      po: 'ATIVO',
    },
    PARTIALLY_FILLED: {
      en: 'PARTIALLY FILLED',
      es: 'LLENADO PARCIAL',
      es_sales: 'LLENADO PARCIAL',
      po: 'PARCIALMENTE PREENCHIDO',
    },
    EXPIRED: {
      en: 'EXPIRED',
      es: 'EXPIRADA',
      es_sales: 'EXPIRADA',
      po: 'EXPIRADO',
    },
    UNKNOWN: {
      en: 'UNKNOWN',
      es: 'DESCONOCIDO',
      es_sales: 'DESCONOCIDO',
      po: 'DESCONHECIDO',
    },
    PENDING_CANCEL: {
      en: 'CANCELING',
      es: 'CANCELANDO',
      es_sales: 'CANCELANDO',
      po: 'CANCELAMENTO',
    },
  },
  orderType: {
    LIMIT: {
      en: 'LIMIT',
      es: 'LÍMITE',
      es_sales: 'LÍMITE',
      po: 'LIMITE',
    },
    MARKET: {
      en: 'MARKET',
      es: 'MERCADO',
      es_sales: 'MERCADO',
      po: 'MERCADO',
    },
    STOP_MARKET: {
      en: 'STOP WITH MARKET',
      es: 'PARAR CON EL MERCADO',
      es_sales: 'PARAR CON EL MERCADO',
      po: 'PARAR COM MERCADO',
    },
    STOP_LIMIT: {
      en: 'STOP WITH LIMIT',
      es: 'PARAR CON LÍMITE',
      es_sales: 'PARAR CON LÍMITE',
      po: 'PARAR COM LIMITE',
    },
    PB: {
      en: 'SWITCH',
      es: 'SWITCH',
      es_sales: 'SWITCH',
      po: 'SWITCH',
    },
    ICEBERG: {
      en: 'ICEBERG',
      es: 'ICEBERG',
      es_sales: 'ICEBERG',
      po: 'ICEBERGUE',
    },
    MAN_OFFSET: {
      en: 'LIMIT',
      es: 'LÍMITE',
      es_sales: 'LÍMITE',
      po: 'LIMITE',
    },
    SLICER: {
      en: 'STEPPED',
      es: 'ESCALONADA',
      es_sales: 'ESCALONADA',
      po: 'ESCALONADA',
    },
    FIXING: {
      en: 'FIXING',
      es: 'FIJAR',
      es_sales: 'FIJAR',
      po: 'FIXAR',
    },
    OCO: {
      en: 'OCO',
      es: 'OCO',
      es_sales: 'OCO',
      po: 'OCO',
    },
    PEGG: {
      en: 'MARKET MAKER',
      es: 'FORMADOR DE MERCADO',
      es_sales: 'FORMADOR DE MERCADO',
      po: 'FORMADOR DE MERCADO',
    },
    PEGGTAKETOP: {
      en: 'MAKER TAKE TOP',
      es: 'MAKER TAKE TOP',
      es_sales: 'MAKER TAKE TOP',
      po: 'MAKER TAKE TOP',
    },
    COPYCAT: {
      en: 'COPYCAT',
      es: 'COPYCAT',
      es_sales: 'COPYCAT',
      po: 'COPYCAT',
    },
  },
  orderSide: {
    BUY: {
      en: 'BUY',
      es: 'COMPRA',
      es_sales: 'B VENDE',
      po: 'COMPRA',
    },
    SELL: {
      en: 'SELL',
      es: 'VENTA',
      es_sales: 'B COMPRA',
      po: 'VENDA',
    },
  },
  warning_rate_above_market: {
    en: 'The rate is above the offer market rate',
    es: 'El tipo de cambio es mayor que el precio de Venta de mercado',
    es_sales: 'El tipo de cambio es mayor que el precio de Venta de mercado',
    po: 'A taxa está acima da taxa de mercado de venda',
  },
  warning_rate_below_market: {
    en: 'The rate is below the bid market rate',
    es: 'El tipo de cambio es menor que el precio de Compra de mercado',
    es_sales: 'El tipo de cambio es menor que el precio de Compra de mercado',
    po: 'A taxa está abaixo das taxas do mercado de compra',
  },
  error_add_price_to_order: {
    en: 'Price is needed',
    es: 'Ingrese el precio de la orden',
    es_sales: 'Ingrese el precio de la orden',
    po: 'Insira o preço do pedido',
  },
  error_price_must_be_greater_than_zero: {
    en: 'Value must be greater than zero',
    es: 'Valor debe ser mayor que cero',
    es_sales: 'Valor debe ser mayor que cero',
    po: 'Valor deve ser maior que zero',
  },
  error_stop_price_incorrect_price: {
    en: 'Incorrect stop price vs expected rate',
    es: 'El valor es incorrecto contra el precio de referencia',
    es_sales: 'El valor es incorrecto contra el precio de referencia',
    po: 'Incorrect stop price vs expected rate',
  },
  error_take_profit_incorrect_price: {
    en: 'Incorrect take profit vs expected rate',
    es: 'El valor es incorrecto contra el precio de referencia',
    es_sales: 'El valor es incorrecto contra el precio de referencia',
    po: 'Incorrect take profit vs expected rate',
  },
  error_stop_price_should_above_market: {
    en: 'The rate should be above the market price',
    es: 'El tipo de cambio debe ser mayor que el precio de Compra de mercado',
    es_sales:
      'El tipo de cambio debe ser mayor que el precio de Compra de mercado',
    po: 'A taxa deve ser acima do preço de mercado',
  },
  error_stop_price_should_below_market: {
    en: 'The rate should be below the market price',
    es: 'El tipo de cambio debe ser menor que el precio de Venta de mercado',
    es_sales: 'El tipo de cambio es menor que el precio de Venta de mercado',
    po: 'A taxa deve ser abaixo do preço de mercado',
  },
  error_price_range_invalid: {
    en: 'Price invalid. Not in allowed range (%7)',
    es: 'Precio inválido. No se encuentra en el rango permitido (7%)',
    es_sales: 'Precio inválido. No se encuentra en el rango permitido (7%)',
    po: 'Preço inválido. Não está no intervalo permitido (7%)',
  },
  error_max_amount_exceeded: {
    en: 'Max amount exceeded',
    es: 'Importe máximo excedido',
    es_sales: 'Importe máximo excedido',
    po: 'Quantidade máxima excedida',
  },
  error_qty_not_avaiblable: {
    en: 'Amount not allowed',
    es: 'Importe no permitido',
    es_sales: 'Importe no permitido',
    po: 'Valor não permitido',
  },
  error_min_amount_of: {
    en: 'Minimum amount must be',
    es: 'La cantidad mínima debe ser de',
    es_sales: 'La cantidad mínima debe ser de',
    po: 'Quantidade mínima deve ser',
  },
  error_iceberg_tip_greater_than_qty: {
    en: 'Iceberg tip cannot be greater than total amount',
    es: 'Punta del iceberg no puede ser mayor que el importe total',
    es_sales: 'Punta del iceberg no puede ser mayor que el importe total',
    po: 'Dica do icebergue não pode ser maior que a quantidade total',
  },
  error_min_qty_zero: {
    en: 'Amount must be greater than 0',
    es: 'Importe debe de ser mayor a 0',
    es_sales: 'Importe debe de ser mayor a 0',
    po: 'Quantidade deve ser maior que 0',
  },
  error_step_greater_than_qty: {
    en: 'Steps cannot be greater than total amount',
    es: 'Escalones no pueden ser mayores al importe total',
    es_sales: 'Escalones no pueden ser mayores al importe total',
    po: 'As etapas não podem ser maiores que o total',
  },
  error_add_step_size: {
    en: 'Add the step size',
    es: 'Ingresa el tamaño del escalon',
    es_sales: 'Ingresa el tamaño del escalon',
    po: 'Digite o tamanho da etapa',
  },
  error_too_many_steps: {
    en: 'Too many steps. Only allowed ', // continue on variable
    es: 'No se permiten tantos escalones. Máximo', // continue on variable
    es_sales: 'No se permiten tantos escalones. Máximo', // continue on variable
    po: 'Muitos passos. Permitido apenas', // continue on variable
  },
  error_interval_time: {
    en: 'Interval must be greater than 0 seconds',
    es: 'Intervalo debe ser mayor a 0 segundos',
    es_sales: 'Intervalo debe ser mayor a 0 segundos',
    po: 'O intervalo deve ser maior que 0 segundo',
  },
  amount: {
    en: 'Amount',
    es: 'Monto',
    es_sales: 'Monto',
    po: 'Quantia',
  },
  select_default_value: {
    en: 'Instrument',
    es: 'Instrumento',
    es_sales: 'Instrumento',
    po: 'Instrumento',
  },
  select_default_tenor: {
    en: 'Tenor',
    es: 'Tenor',
    es_sales: 'Tenor',
    po: 'Tenor',
  },
};

export const flexLayout = {
  popoutItem: {
    en: 'Widget is in popout.',
    es: 'Componente en otra ventana.',
    es_sales: 'Componente en otra ventana.',
    po: 'Componente em outra janela.',
  },
  tabCloseConfirm: {
    en: 'Popout widgets will also close',
    es: 'Los siguientes popouts se cerrarán',
    es_sales: 'Los siguientes popouts se cerrarán',
    po: 'Los siguientes popouts se cerrarán',
  },
  blotter: {
    en: 'Blotter',
    es: 'Bitácora',
    es_sales: 'Bitácora',
    po: 'Registro de Operações',
  },
  chart: {
    en: 'Chart',
    es: 'Gráfica',
    es_sales: 'Gráfica',
    po: 'Gráfico',
  },
  instrumentsTrading: {
    en: 'Board - Trading',
    es: 'Tablero Informativo - Ejecución',
    es_sales: 'Tablero Informativo - Ejecución',
    po: 'Ativo - Trading',
  },
  instruments: {
    en: 'Board',
    es: 'Tablero Informativo',
    es_sales: 'Tablero Informativo',
    po: 'Tabela Informativa',
  },
  orders: {
    en: 'Orders',
    es: 'Panel de Órdenes',
    es_sales: 'Panel de Órdenes',
    po: 'Ordens',
  },
  vwap: {
    en: 'VWAP',
    es: 'VWAP',
    es_sales: 'VWAP',
    po: 'VWAP',
  },
  account: {
    en: 'Acc. Manager',
    es: 'Manejador de Ctas',
    es_sales: 'Manejador de Ctas',
    po: 'Acc. Manager',
  },
  client_pricing: {
    en: 'Client Pricing',
    es: 'Precio del Cliente',
    es_sales: 'Precio del Cliente',
    po: 'Preços do Cliente',
  },
  trading: {
    en: 'Trading',
    es: 'Ejecución',
    es_sales: 'Ejecución',
    po: 'Comercialização',
  },
  pegg: {
    en: 'Market Maker',
    es: 'Formador de Mercado',
    es_sales: 'Formador de Mercado',
    po: 'Formador de Mercado',
  },
  copycat: {
    en: 'COPYCAT',
    es: 'COPYCAT',
    es_sales: 'COPYCAT',
    po: 'COPYCAT',
  },
  multiCurrency: {
    en: 'Multicurrency Trading',
    es: 'Ejecución Multimoneda',
    es_sales: 'Ejecución Multimoneda',
    po: 'Operação Multi Moeda',
  },
  rfs: {
    en: 'Streaming Forward & Swaps',
    es: 'Forwards and Swaps',
    es_sales: 'Forwards and Swaps',
    po: 'Streaming Forward & Swaps',
  },
  rfq: {
    en: 'RFQ',
    es: 'RFQ',
    es_sales: 'RFQ',
    po: 'RFQ',
  },
  rfq_blotter: {
    en: 'RFQ Blotter',
    es: 'Bitácora RFQ',
    es_sales: 'Bitácora RFQ',
    po: 'Registro de Operações RFQ',
  },
  pnl: {
    en: 'Historic PNL',
    es: 'Histórico PNL',
    es_sales: 'Histórico PNL',
    po: 'Historico Lucro e Perda',
  },
  pnl2: {
    en: 'Profit & Loss',
    es: 'Pérdidas & Ganancias',
    es_sales: 'Pérdidas & Ganancias',
    po: 'Lucro & Perda',
  },
  ladder: {
    en: 'Ladder',
    es: 'Ladder',
    es_sales: 'Ladder',
    po: 'Ladder',
  },
  restingOrders: {
    en: 'Resting Orders',
    es: 'Órdenes Pasivas',
    es_sales: 'Órdenes Pasivas',
    po: 'Ordens Passivas',
  },
  economic_calendar: {
    en: 'Economic Calendar',
    es: 'Calendario Económico',
    es_sales: 'Calendario Económico',
    po: 'Calendario Económico',
  },
  rss_feed: {
    en: 'Daily FX',
    es: 'FX Diario',
    es_sales: 'FX Diario',
    po: 'FX Diario',
  },
  heatmap: {
    en: 'Heatmap',
    es: 'Heatmap',
    es_sales: 'Heatmap',
    po: 'Heatmap',
  },
  pricing: {
    en: 'Pricing',
    es: 'Precios',
    es_sales: 'Precios',
    po: 'Preços',
  },
  labelClose: {
    en: 'Close',
    es: 'Cerrar',
    es_sales: 'Cerrar',
    po: 'Fechar',
  },
  labelMaximize: {
    en: 'Maximize',
    es: 'Maximizar',
    es_sales: 'Maximizar',
    po: 'Maximizar',
  },
  labelMinimize: {
    en: 'Minimize',
    es: 'Minimizar',
    es_sales: 'Minimizar',
    po: 'Minimizar',
  },
  labelPopout: {
    en: 'Open in new window',
    es: 'Abrir en nueva ventana',
    es_sales: 'Abrir en nueva ventana',
    po: 'Abrir em nova janela',
  },
};

export const instruments_trading_widget = {
  fl_title: flexLayout.instrumentsTrading,
};

export const blotter_widget = {
  common,
  fl_title: flexLayout.blotter,
  hf_initial: {
    en: 'From',
    es: 'De',
    es_sales: 'De',
    po: 'De',
  },
  hf_final: {
    en: 'To',
    es: 'A',
    es_sales: 'A',
    po: 'A',
  },
  hf_action: {
    en: 'Update',
    es: 'Actualizar',
    es_sales: 'Actualizar',
    po: 'Atualizar',
  },
  title_pdf: {
    en: 'Trade Report',
    es: 'Reporte de Operaciones',
    es_sales: 'Reporte de Operaciones',
    po: 'Relatório de Operações',
  },
  todayTrades: {
    en: 'Trades',
    es: 'Operaciones',
    es_sales: 'Operaciones',
    po: 'Operações',
  },
  yesterdayTrades: {
    en: 'Yesterday´s Trades',
    es: 'Operaciones de Ayer',
    es_sales: 'Operaciones de Ayer',
    po: 'Operações de ontem',
  },
  operations_week: {
    en: "This Week's Trades",
    es: 'Operaciones de la Semana',
    es_sales: 'Operaciones de la Semana',
    po: 'Operações da semana',
  },
  todayOrders: {
    en: "Today's Orders",
    es: 'Órdenes de hoy',
    es_sales: 'Órdenes de hoy',
    po: 'Ordens de hoje',
  },
  coverOrders: {
    en: 'Covers',
    es: 'Coberturas',
    es_sales: 'Coberturas',
    po: 'Covers',
  },
  historicTrades: {
    en: 'Trade history',
    es: 'Historico de Operaciones',
    es_sales: 'Historico de Operaciones',
    po: 'Hstórico de operações',
  },
  profits_today: {
    en: "Today's P&L",
    es: 'Ganancias de Hoy',
    es_sales: 'Ganancias de Hoy',
    po: 'Lucro de hoje',
  },
  profits_dates: {
    en: 'Custom Dates P&L',
    es: 'Ganancias entre Fechas',
    es_sales: 'Ganancias entre Fechas',
    po: 'Lucro entre datas',
  },
  button_select_dates_placeholder: {
    en: 'Select a Range',
    es: 'Seleccione un rango de fechas',
    es_sales: 'Seleccione un rango de fechas',
    po: ' Selecione um intervalo',
  },
  select_and_autosum_buy: {
    en: 'Buy',
    es: 'Compra',
    es_sales: 'B Vende',
    po: 'Compra',
  },
  net_price: {
    en: 'Net price',
    es: 'Precio neto',
    es_sales: 'Precio neto',
    po: 'Net price',
  },
  vwap_loading: {
    en: 'Calculated ...',
    es: 'Calculando ...',
    es_sales: 'Calculando ...',
    po: 'Calculado ...',
  },
  select_and_autosum_sell: {
    en: 'Sell',
    es: 'Venta',
    es_sales: 'B Compra',
    po: 'Venda',
  },
  select_and_autosum_pos: {
    en: 'Pos',
    es: 'Pos',
    es_sales: 'Pos',
    po: 'Pos',
  },
  select_and_autosum_count: {
    en: 'Trades',
    es: 'Operaciones',
    es_sales: 'Operaciones',
    po: 'Operações',
  },
  button_filter: {
    en: 'Filter',
    es: 'Filtrar',
    es_sales: 'Filtrar',
    po: 'Filtrar',
  },
  re_client_name: {
    en: 'Client',
    es: 'Cliente',
    es_sales: 'Cliente',
    po: 'Cliente',
  },
  re_client_org_name: {
    en: 'Client Org',
    es: 'Organización cliente',
    es_sales: 'Organización cliente',
    po: 'Organização do cliente',
  },
  re_client_fill_price: {
    en: 'Final client rate',
    es: 'Cliente Tipo de Cambio',
    es_sales: 'Cliente Tipo de Cambio',
    po: 'Taxa final',
  },
  re_client_order_type: {
    en: 'Client Order Type',
    es: 'Cliente Tipo de Orden',
    es_sales: 'Cliente Tipo de Orden',
    po: 'Cliente Tipo de Ordem',
  },
  client_order_id: {
    en: 'ID',
    es: 'ID',
    es_sales: 'ID',
    po: 'ID',
  },
  parent_id: {
    en: 'Parent ID',
    es: 'Parent ID',
    es_sales: 'Parent ID',
    po: 'Parent ID',
  },
  organization_name: {
    en: 'Organization',
    es: 'Organización',
    es_sales: 'Organización',
    po: 'Organização',
  },
  username: {
    en: 'Username',
    es: 'Usuario',
    es_sales: 'Usuario',
    po: 'Usuário',
  },
  status: {
    en: 'Status',
    es: 'Estatus',
    es_sales: 'Estatus',
    po: 'Status',
  },
  client_side: {
    en: 'Client Side',
    es: 'Lado del cliente',
    es_sales: 'Lado del cliente',
    po: 'Lado do Cliente',
  },
  reverse: {
    en: 'Unwind',
    es: 'Reverso',
    es_sales: 'Reverso',
    po: 'Descontrair',
  },
  operation_date: {
    en: 'Creation Date',
    es: 'Fecha de Captura',
    es_sales: 'Fecha de Captura',
    po: 'Data de captura',
  },
  order_type: {
    en: 'Order Type',
    es: 'Tipo de Orden',
    es_sales: 'Tipo de Orden',
    po: 'Tipo de Ordem',
  },
  tif: {
    en: 'TIF',
    es: 'TIF',
    es_sales: 'TIF',
    po: 'TIF',
  },
  expiration_date: {
    en: 'Expiry',
    es: 'Fecha de Vigencia',
    es_sales: 'Fecha de Vigencia',
    po: 'Data de Vigência',
  },
  symbol: {
    en: 'Symbol',
    es: 'Instrumento',
    es_sales: 'Instrumento',
    po: 'Instrumento',
  },
  base_qty_total: {
    en: 'Base Amount',
    es: 'Importe Base',
    es_sales: 'Importe Base',
    po: 'Valor base',
  },
  term_qty_total: {
    en: 'Term Amount',
    es: 'Importe Termino',
    es_sales: 'Importe Termino',
    po: 'Valor do Termo',
  },
  qty_total: {
    en: 'Order Amount',
    es: 'Importe de Orden',
    es_sales: 'Importe de Orden',
    po: 'Valor Quantidade da Ordem',
  },
  side: {
    en: 'B/S',
    es: 'C/V',
    es_sales: 'C/V',
    po: 'C/V',
  },
  price: {
    en: 'Requested Price',
    es: 'Precio Solicitado',
    es_sales: 'Precio Solicitado',
    po: 'Preço Solicitado',
  },
  term_amount: {
    en: 'Term Amount',
    es: 'Contraimporte',
    es_sales: 'Contraimporte',
    po: 'Contra-apoio',
  },
  filled_price_vwap: {
    en: 'Rate',
    es: 'Tipo de Cambio',
    es_sales: 'Tipo de Cambio',
    po: 'Taxa Original',
  },
  client_filled_price_vwap: {
    en: 'Rate',
    es: 'Tipo de Cambio Cliente',
    es_sales: 'Tipo de Cambio Cliente',
    po: 'Taxa Original Cliente',
  },
  value_date: {
    en: 'Value Date',
    es: 'Fecha Valor',
    es_sales: 'Fecha Valor',
    po: 'Data de Validade',
  },
  trade_date: {
    en: 'Trade Date',
    es: 'Fecha de Operación',
    es_sales: 'Fecha Operación',
    po: 'Data de Negociação',
  },
  quantity_filled: {
    en: 'Filled Amount',
    es: 'Importe Llenado',
    es_sales: 'Importe Llenado',
    po: 'Valor Preenchido',
  },
  quantity_pending: {
    en: 'Pending Amount',
    es: 'Importe Pendiente',
    es_sales: 'Importe Pendiente',
    po: 'Valor Pendente',
  },
  qty_original: {
    en: 'Requested Amount',
    es: 'Importe Solicitado',
    es_sales: 'Importe Solicitado',
    po: 'Quantidade Solicitada',
  },
  fixing_date: {
    en: 'Fixing Date',
    es: 'Fecha Fixing',
    es_sales: 'Fecha Fixing',
    po: 'Fixing Date',
  },
  publish_date: {
    en: 'Publish Date',
    es: 'Fecha de Publicacion',
    es_sales: 'Fecha de Publicacion',
    po: 'Publish Date',
  },
  publish_maturity_date: {
    en: 'Publish/Maturity Date',
    es: 'Fecha de Publicación/Vencimiento',
    es_sales: 'Fecha de Publicación/Vencimiento',
    po: 'Publish/Maturity Date',
  },
  version: {
    en: 'Version',
    es: 'Versión',
    es_sales: 'Versión',
    po: 'Version',
  },
  switch_client: {
    en: 'Switch Client',
    es: 'Cliente Switch',
    es_sales: 'Cliente Switch',
    po: 'Switch Client',
  },
  switch_bank: {
    en: 'Switch Bank',
    es: 'Banco Switch',
    es_sales: 'Banco Switch',
    po: 'Switch Bank',
  },
  err_return_to_realize: {
    en: 'Trying to reconnect',
    es: 'Tratando de reconectar T.C.',
    es_sales: 'Tratando de reconectar T.C.',
    po: 'Tentando se reconectar',
  },
  err_basic: {
    en: 'Error 101',
    es: 'Error 101',
    es_sales: 'Error 101',
    po: 'Erro 101',
    description: 'Cannot access to prices',
  },
  pair: {
    en: 'Pair',
    es: 'Par',
    es_sales: 'Par',
    po: 'Par',
  },
  positions: {
    en: 'Position',
    es: 'Posición',
    es_sales: 'Posición',
    po: 'Posição',
  },
  qty_buys: {
    en: 'Bought Amount Base CCY',
    es: 'Importe Base Compras',
    es_sales: 'Importe Base B ventas',
    po: 'Quantidade Base de Compra Moedas',
  },
  tc_buys_weighted: {
    en: 'Bought Rate',
    es: 'TC Compras',
    es_sales: 'TC B Venta',
    po: 'Taxa Comprada',
  },
  qty_sells: {
    en: 'Sold Amount Base CCY',
    es: 'Importe Base Ventas',
    es_sales: 'Importe Base B Compra',
    po: 'Quantidade Base de Vendas Moedas',
  },
  tc_sells_weighted: {
    en: 'Sold Rate',
    es: 'TC Ventas',
    es_sales: 'TC B Compras',
    po: 'Taxa Venda',
  },
  qty_per_fill_price_vwap_buys: {
    en: 'Bought Term Amount',
    es: 'Contraimporte Compras',
    es_sales: 'Contraimporte B Venta',
    po: 'Bought Term Amount',
  },
  qty_per_fill_price_vwap_sells: {
    en: 'Sold Term Amount',
    es: 'Contraimporte Ventas',
    es_sales: 'Contraimporte B Compra',
    po: 'Sold Term Amount',
  },
  tenor: {
    en: 'Tenor',
    es: 'Tenor',
    es_sales: 'Tenor',
    po: 'Tenor',
  },
  timestamp: {
    en: 'Execution Time',
    es: 'Fecha de Ejecución',
    es_sales: 'Fecha de Ejecución',
    po: 'Data de Comercialização',
  },
  shadowGroup: {
    en: 'Strategy Orders',
    es: 'Strategy Orders',
    es_sales: 'Strategy Orders',
    po: 'Strategy Orders',
  },
  shadow_group: {
    en: 'Strategy Group',
    es: 'Strategy Group',
    es_sales: 'Strategy Group',
    po: 'Strategy Group',
  },
  spot_rate: {
    en: 'Spot rate',
    es: 'Tasa al contado',
    es_sales: 'Tasa al contado',
    po: 'Preço Spot',
  },
  cover_spot_rate: {
    en: 'Cover Spot Rate',
    es: 'Tasa al contado Cobertura',
    es_sales: 'Tasa al contado Cobertura',
    po: 'Taxa de spot Cobertura',
  },
  client_spot_rate: {
    en: 'Client Spot Rate',
    es: 'Tasa al contado Cliente',
    es_sales: 'Tasa al contado Cliente',
    po: 'Taxa de spot cliente',
  },
  rfs_type: {
    en: 'Type',
    es: 'Tipo',
    es_sales: 'Tipo',
    po: 'Type',
  },
  rfs_near_leg_settlement: {
    en: 'Near Leg Settlement',
    es: 'Vencimiento Pata Corta',
    es_sales: 'Vencimiento Pata Corta',
    po: 'Near Leg Settlement',
  },
  rfs_near_fix_date: {
    en: 'Near FIX Date',
    es: 'Fixing Pata Corta',
    es_sales: 'Fixing Pata Corta',
    po: 'Near FIX Date',
  },
  rfs_far_leg_settlement: {
    en: 'Far Leg Settlement',
    es: 'Vencimiento Pata Larga',
    es_sales: 'Vencimiento Pata Larga',
    po: 'Far Leg Settlement',
  },
  rfs_far_fix_date: {
    en: 'Far FIX Date',
    es: 'Fixing Pata Larga',
    es_sales: 'Fixing Pata Larga',
    po: 'Far FIX Date',
  },
  rfs_swap_points: {
    en: 'Swap Points',
    es: 'Puntos Swap',
    es_sales: 'Puntos Swap',
    po: 'Swap Points',
  },
  re_spread: {
    en: 'RE Spread',
    es: 'RE Spread',
    es_sales: 'RE Spread',
    po: 'RE Spread',
  },
  forward_points: {
    en: 'Forward points',
    es: 'Puntos de avance',
    es_sales: 'Puntos de avance',
    po: 'Pontos para frente',
  },
  cover_forward_points: {
    en: 'Cover Forward Points',
    es: 'Puntos de avance Cobertura',
    es_sales: 'Puntos de avance Cobertura',
    po: 'Pontos para frente Cobertura',
  },
  client_forward_points: {
    en: 'Client Forward Points',
    es: 'Puntos de avance Cliente',
    es_sales: 'Puntos de avance Cliente',
    po: 'Pontos para frente Cliente',
  },
  start_time: {
    en: 'Starting Date',
    es: 'Fecha de Inicio',
    es_sales: 'Fecha de Inicio',
    po: 'Data de início',
  },
  client_note: {
    en: 'Notes',
    es: 'Notas',
    es_sales: 'Notas',
    po: 'Notas',
  },
  counterparty: {
    en: 'Counterparty',
    es: 'Contraparte',
    es_sales: 'Contraparte',
    po: 'Contraparte',
  },
  brokerage_currency: {
    en: 'Brokerage Currency',
    es: 'Moneda de corretaje',
    es_sales: 'Moneda de corretaje',
    po: 'Moeda de corretagem',
  },
  brokerage_commision: {
    en: 'Brokerage Commision',
    es: 'Comisión de corretaje',
    es_sales: 'Comisión de corretaje',
    po: 'Comissão de Corretagem',
  },
  confirm_text_cancel: {
    en: 'Cancel order with ID ',
    es: 'Cancelar orden con ID ',
    es_sales: 'Cancelar orden con ID ',
    po: 'Cancelar  orden com ID',
  },
  direction: {
    BUY: {
      en: 'Buy ',
      es: 'Compra ',
      es_sales: 'B Venta ',
      po: 'Compra',
    },
    SELL: {
      en: 'Sell ',
      es: 'Venta ',
      es_sales: 'B Compra ',
      po: 'Venda',
    },
  },
  confirm_yes: {
    en: 'Yes',
    es: 'Si',
    es_sales: 'Si',
    po: 'Sim',
  },
  confirm_no: {
    en: 'No',
    es: 'No',
    es_sales: 'No',
    po: 'Não',
  },
  add_column: {
    en: 'Add column',
    es: 'Agregar columna',
    es_sales: 'Agregar columna',
    po: 'Adicionar coluna',
  },
  export: {
    en: 'Export',
    es: 'Exportar',
    es_sales: 'Exportar',
    po: 'Exportar',
  },
  to: {
    en: 'to',
    es: 'a',
    es_sales: 'a',
    po: 'para',
  },
  row_collapsed: {
    en: 'Row collapsed',
    es: 'Fila colapsada',
    es_sales: 'Fila colapsada',
    po: 'Linha recolhida',
  },
  config_title: {
    en: 'Blotter Widget Settings',
    es: 'Configuración del panel Bitácora',
    es_sales: 'Configuración del panel Bitácora',
    po: 'Configurações do painel de Registro de Operações',
  },
  no_rows_selected: {
    en: 'Please select some rows first...',
    es: 'Selecciona alguna fila primero...',
    es_sales: 'Selecciona alguna fila primero...',
    po: 'Selecione algumas linhas primeiro...',
  },
  no_trades_found: {
    en: 'No orders found in this timeframe...',
    es: 'No se encontraron ordenes en este periodo...',
    es_sales: 'No se encontraron ordenes en este periodo...',
    po: 'Nenhuma ordens encontrada neste período de tempo...',
  },
  mrkt_price: {
    en: 'Mrkt',
    es: 'Mercado',
    es_sales: 'Mercado',
    po: 'Mercado',
  },
  title_confirm_reverse: {
    en: 'Order confirmation',
    es: 'Confirmación de orden',
    es_sales: 'Confirmación de orden',
    po: 'Confirmação de pedido',
  },
  total_amount: {
    en: 'Total Amount',
    es: 'Importe total ',
    es_sales: 'Importe total ',
    po: 'Quantidade Total',
  },
  text_market: {
    en: 'Market',
    es: 'Mercado',
    es_sales: 'Mercado',
    po: 'Mercado',
  },
  btn_cancel: {
    en: 'Cancel',
    es: 'Cancelar',
    es_sales: 'Cancelar',
    po: 'Cancelar',
  },
  btn_confirm: {
    en: 'Confirm',
    es: 'Confirmar',
    es_sales: 'Confirmar',
    po: 'Confirme',
  },
};

export const header_panel = {
  title_status_server: {
    en: 'Server Status',
    es: 'Estatus del servidor',
    es_sales: 'Estatus del servidor',
    po: 'Status do servidor',
  },
  disable_trading: {
    en: 'Disable Trading',
    es: 'Bloquear Plataforma',
    es_sales: 'Bloquear Plataforma',
    po: 'Desabilitar Comércio',
  },
  enable_trading: {
    en: 'Enable Trading',
    es: 'Desbloquear Plataforma',
    es_sales: 'Desbloquear Plataforma',
    po: 'Ativar Comércio',
  },
  connected: {
    en: 'Online',
    es: 'En línea',
    es_sales: 'En línea',
    po: 'Em linha',
  },
  disconnected: {
    en: 'Disconnected',
    es: 'Desconectado',
    es_sales: 'Desconectado',
    po: 'Desconectado',
  },
  user_profile: {
    en: 'Profile',
    es: 'Perfil de usuario',
    es_sales: 'Perfil de usuario',
    po: 'Perfil de usuário',
  },
  profits_dates: {
    en: 'Custom Dates P&L',
    es: 'Ganancias entre Fechas',
    es_sales: 'Ganancias entre Fechas',
    po: 'Lucro entre datas',
  },
  configuration: {
    en: 'Settings',
    es: 'Configuración',
    es_sales: 'Configuración',
    po: 'Configuração',
  },
  change_password: {
    en: 'Change password',
    es: 'Cambiar contraseña',
    es_sales: 'Cambiar contraseña',
    po: 'Mudar senha',
  },
  design: {
    en: 'Design',
    es: 'Diseño',
    es_sales: 'Diseño',
    po: 'Desenho',
  },
  logout: {
    en: 'Logout',
    es: 'Cerrar sesión',
    es_sales: 'Cerrar sesión',
    po: 'Fechar Sessão',
  },
  credit_limit: {
    en: 'Credit Limit',
    es: 'Límite de Crédito',
    es_sales: 'Límite de Crédito',
    po: 'Limite de Crédito',
  },
  err_connection_lost: {
    matchers: {
      en: '(502) There was a problem connecting to the pricing server. Please try to reconnect',
      es: '(502) Se ha perdido la conexión de precios. Intenta reconectar de nuevo',
      es_sales:
        '(502) Se ha perdido la conexión de precios. Intenta reconectar de nuevo',
      po: '(502) Conexão perdida, por favor reconectar novamente',
    },
    wsStatus: {
      en: '(504) There was a problem in the connection to the webserver. Please try to reconnect',
      es: '(504) La conexión se ha perdido a nuestro servidor web. Intenta reconectar de nuevo',
      es_sales:
        '(504) La conexión se ha perdido a nuestro servidor web. Intenta reconectar de nuevo',
      po: '(504) Houve um problema na conexão com o servidor.Por favor, tente reconectar',
    },
    webSocketStatus: {
      en: '(505) There was a problem in the connection of the websockets. Please try to reconnect',
      es: '(505) La conexión se ha perdido de nuestros websockets. Intenta reconectar de nuevo',
      es_sales:
        '(505) La conexión se ha perdido de nuestros websockets. Intenta reconectar de nuevo',
      po: '(505) Houve um problema na conexão com o servidor.Por favor, tente reconectar',
    },
    uiStatus: {
      en: '(503) The client could not connect to the UI. Please try to reconnect',
      es: '(503) La conexión se ha perdido. Intenta reconectar de nuevo',
      es_sales: '(503) La conexión se ha perdido. Intenta reconectar de nuevo',
      po: '(503) Houve um problema na conexão com o servidor.Por favor, tente reconectar',
    },
  },
  err_connection_lost_button: {
    en: 'Reconnect',
    es: 'Reconectar',
    es_sales: 'Reconectar',
    po: 'Reconectar',
  },
  active: {
    en: 'active',
    es: 'activo',
    es_sales: 'activo',
    po: 'activo',
  },
  inactive: {
    en: 'inactive',
    es: 'inactivo',
    es_sales: 'inactivo',
    po: 'inativo',
  },
  trading_disabled: {
    en: 'Trading disabled',
    es: 'Compraventa deshabilitado',
    es_sales: 'Compraventa deshabilitado',
    po: 'Compra e venda desativada',
  },
  btn_cancel_all: {
    en: 'Cancel All',
    es: 'Cancelar Todo',
    es_sales: 'Cancelar Todo',
    po: 'Cancelar tudo',
  },
  tooltip_btn_cancel_all: {
    en: 'Cancel all active orders',
    es: 'Cancela todas las órdenes activas',
    es_sales: 'Cancela todas las órdenes activas',
    po: 'Cancelar todas as ordens ativas',
  },
  tooltip_support_contacts: {
    en: 'Support Contact(s)',
    es: 'Contacto(s) de Soporte',
    es_sales: 'Contacto(s) de Soporte',
    po: 'Contacto(s) de Suporte',
  },
  global_phone_number: {
    en: 'Global Phone Number',
    es: 'Número Global de Soporte',
    es_sales: 'Número Global de Soporte',
    po: 'Número Global de Suporte',
  },
  region_phone_numbers: {
    en: 'Region Phone Number(s)',
    es: 'Número Regional de Soporte',
    es_sales: 'Número Regional de Soporte',
    po: 'Número Regional de Suporte',
  },
  region_emails: {
    en: 'Region Email(s)',
    es: 'Correo regional',
    es_sales: 'Correo regional',
    po: 'Email regional',
  },
};

export const instruments_widget = {
  fl_title: flexLayout.instruments,
  button_add_pair: {
    en: 'Add CCY Pair',
    es: 'Agregar Par',
    es_sales: 'Agregar Par',
    po: 'Agregar par',
  },
  button_delete_pair: {
    en: 'Delete',
    es: 'Eliminar',
    es_sales: 'Eliminar',
    po: 'Excluir',
  },
  label_max: {
    en: 'Maximum number of pairs added.',
    es: 'Número máximo de pares agregados.',
    es_sales: 'Número máximo de pares agregados.',
    po: 'Número máximo de pares adicionados.',
  },
};

export const orders_widget = {
  fl_title: flexLayout.orders,
  text_requested: {
    en: 'Requested',
    es: 'Solicitado',
    es_sales: 'Solicitado',
    po: 'Realizadas',
  },
  text_filled: {
    en: 'Filled',
    es: 'Llenado',
    es_sales: 'Llenado',
    po: 'Preenchidas',
  },
  text_qty: {
    en: 'Amount',
    es: 'Cantidad',
    es_sales: 'Cantidad',
    po: 'Quantidade',
  },
  text_exchange_price: {
    en: 'Rate',
    es: 'Tipo de Cambio',
    es_sales: 'Tipo de Cambio',
    po: 'Preço',
  },
  text_delete: {
    en: 'Delete',
    es: 'Borrar',
    es_sales: 'Borrar',
    po: 'Excluir',
  },
  text_cancel: {
    en: 'Cancel',
    es: 'Cancelar',
    es_sales: 'Cancelar',
    po: 'Cancelar',
  },
  text_market: {
    en: 'Market',
    es: 'Mercado',
    es_sales: 'Mercado',
    po: 'Mercado',
  },
  title_buy: {
    en: 'Buy ',
    es: 'Compra ',
    es_sales: 'Banco venta ',
    po: 'Compra ',
  },
  title_sell: {
    en: 'Sell ',
    es: 'Venta ',
    es_sales: 'Banco Compra ',
    po: 'Venda ',
  },
  confirm_text_cancel: {
    en: 'Cancel order with ID ',
    es: 'Cancelar orden con ID ',
    es_sales: 'Cancelar orden con ID ',
    po: 'Cancelar ordem com ID',
  },
  confirm_text_cancel_if_done: {
    en: 'this will cancel TP and SL',
    es: 'cancelará TP y SL',
    es_sales: 'cancelará TP y SL',
    po: 'this will cancel TP and SL',
  },
  title: {
    en: 'Orders Panel',
    es: 'Panel de Órdenes',
    es_sales: 'Panel de Órdenes',
    po: 'Painel de Ordens',
  },
  button_all: {
    en: 'All',
    es: 'Todas',
    es_sales: 'Todas',
    po: 'Todas',
  },
  button_active: {
    en: 'Active',
    es: 'Activas',
    es_sales: 'Activas',
    po: 'Ativas',
  },
  button_terminated: {
    en: 'Finished',
    es: 'Terminadas',
    es_sales: 'Terminadas',
    po: 'Finalizadas',
  },
  button_cancel_all: {
    en: 'Cancel All',
    es: 'Cancelar Todo',
    es_sales: 'Cancelar Todo',
    po: 'Cancelar tudo',
  },
  direction: {
    BUY: {
      en: 'Buy ',
      es: 'Compra ',
      es_sales: 'B venta ',
      po: 'Compra ',
    },
    SELL: {
      en: 'Sell ',
      es: 'Venta ',
      es_sales: 'B Compra ',
      po: 'Venda ',
    },
  },
  confirm_yes: {
    en: 'Yes',
    es: 'Si',
    es_sales: 'Si',
    po: 'Sim',
  },
  confirm_no: {
    en: 'No',
    es: 'No',
    es_sales: 'No',
    po: 'Não',
  },
};

export const clientPricingWidget = {
  fl_title: {
    en: 'Client Pricing',
    es: 'Precio del Cliente',
    es_sales: 'Precio del Cliente',
    po: 'Preços do Cliente',
  },
  settle_date: {
    en: 'Settlement Date',
    es: 'Fecha Valor',
    es_sales: 'Fecha Valor',
  },
  fixing_date: {
    en: 'Fixing Date',
    es: 'Fecha Fixing',
    es_sales: 'Fecha Fixing',
    po: 'Fixing Date',
  },
  account: {
    en: 'Account',
    es: 'Cuenta',
    es_sales: 'Cuenta',
    po: 'Conta',
  },
  forward_points: {
    en: 'FWD Points',
    es: 'Puntos Frwd',
    es_sales: 'Puntos Frwd',
    po: 'Pontos para frente',
  },
  spot_rate: {
    en: 'Spot',
    es: 'Spot',
    es_sales: 'Spot',
    po: 'Spot',
  },
  client: {
    en: 'Client',
    es: 'Tipo de Cambio Pactado',
    es_sales: 'Tipo de Cambio Pactado',
    po: 'Client',
  },
  tenor: {
    en: 'Tenor',
    es: 'Plazo',
    es_sales: 'Plazo',
    po: 'Tenor',
  },
  total_amount: {
    en: 'Total Amount',
    es: 'Importe total',
    es_sales: 'Importe total',
    po: 'Quantidade Total',
  },
  term_amount: {
    en: 'Term Amount',
    es: 'Contravalor',
    es_sales: 'Contravalor',
    po: 'Contra-apoio',
  },
  amount: {
    en: 'Amount',
    es: 'Importe',
    es_sales: 'Importe',
    po: 'Quantidade',
  },
  final_rate: {
    en: 'Final Rate',
    es: 'Tipo de Cambio',
    es_sales: 'Tipo de Cambio',
    po: 'Taxa final',
  },
  client_rate: {
    en: 'Client Rate',
    es: 'Precio Cliente',
    es_sales: 'Precio Cliente',
    po: 'Taxa de clientes',
  },
  order_reject: {
    en: 'Order Rejected',
    es: 'Orden Rechazada',
    es_sales: 'Orden Rechazada',
    po: 'Orden Rejeitado',
  },
  order_submitted: {
    en: 'Order Submitted',
    es: 'Orden Enviada',
    es_sales: 'Orden Enviada',
    po: 'Orden Submetido',
  },
  cover: {
    en: 'Cover',
    es: 'Tipo de Cambio Base',
    es_sales: 'Tipo de Cambio Base',
    po: 'Cover',
  },
  final_client_rate: {
    en: 'Client All-in Rate',
    es: 'Precio Final Cliente',
    es_sales: 'Precio Final Cliente',
    po: 'Client All-in Rate',
  },
  final_cover_rate: {
    en: 'Cover All-In Rate',
    es: 'Precio Final Cobertura',
    es_sales: 'Precio Final Cobertura',
    po: 'Cover All-In Rate',
  },
  spread: {
    en: 'Spread',
    es: 'Margen',
    es_sales: 'Margen',
    po: 'Márgem',
  },
  profit: {
    en: 'Profit',
    es: 'Ingreso',
    es_sales: 'Ingreso',
    po: 'Profit',
  },
  tradeId: {
    en: 'Trade ID',
    es: 'ID Operación',
    es_sales: 'ID Operación',
    po: 'ID Operação',
  },
  labelClose: {
    en: 'Close',
    es: 'Cerrar',
    es_sales: 'Cerrar',
    po: 'Fechar',
  },
  confirm_order_title: {
    en: 'Please confirm the deal',
    es: 'Confirmar Operación',
    es_sales: 'Confirmar Operación',
    po: 'Please confirm the deal',
  },
  trade_details: {
    en: 'Trade Details',
    es: 'Detalles de Operación',
    es_sales: 'Detalles de Operación',
    po: 'Trade Details',
  },
  details: {
    en: 'Details',
    es: 'Detalles',
    es_sales: 'Detalles',
    po: 'Details',
  },
  deal_done: {
    en: 'Deal Done',
    es: 'Operación Ejecutada',
    es_sales: 'Operación Ejecutada',
    po: 'Deal Done',
  },
  unknown_select_account: {
    en: 'Select an Account',
    es: 'Selecciona una cuenta',
    es_sales: 'Selecciona una cuenta',
    po: 'Seleccione uma conta',
  },
  required: {
    en: 'This value is required',
    es: 'Valor requerido',
    es_sales: 'Valor requerido',
    po: 'Este valor é obrigatório',
  },
  disclaimerText: {
    en: 'I certify that I have received in writing from the Client, through the authorized person or email, the request for the following order.',
    es: 'Certifico que recibí por escrito de parte del Cliente, a través de la persona o correo facultados para ello, la solicitud de la siguiente Postura',
    es_sales:
      'Certifico que recibí por escrito de parte del Cliente, a través de la persona o correo facultados para ello, la solicitud de la siguiente Postura',
    po: 'Certifico que recebi por escrito do Cliente, por meio de pessoa autorizada ou e-mail, a solicitação da seguinte postura.',
  },
};

export const modals = {
  label_direction: {
    en: 'Direction',
    es: 'Dirección',
    es_sales: 'Dirección',
    po: 'Direção ',
  },
  password_change_modal: {
    title: {
      en: 'Change password',
      es: 'Cambio de contraseña',
      es_sales: 'Cambio de contraseña',
      po: 'Mudar senha',
    },
    actual_pw: {
      en: 'Current password',
      es: 'Contraseña actual',
      es_sales: 'Contraseña actual',
      po: 'Senha atual',
    },
    new_pw: {
      en: 'New password',
      es: 'Nueva contraseña',
      es_sales: 'Nueva contraseña',
      po: 'Nova senha',
    },
    new_pw_confirm: {
      en: 'Confirm new password',
      es: 'Confirmar nueva contraseña',
      es_sales: 'Confirmar nueva contraseña',
      po: 'Confirme a nova senha',
    },
    confirm_yes: {
      en: 'Change password',
      es: 'Cambiar contraseña',
      es_sales: 'Cambiar contraseña',
      po: 'Mudar senha',
    },
    confirm_no: {
      en: 'Cancel',
      es: 'Cancelar',
      es_sales: 'Cancelar',
      po: 'Cancelar',
    },
    invalid_password: {
      en: 'New password must be at least 8 characters long and contain at least one of a-z, A-Z and 0-9',
      es: 'La nueva contraseña debe de estar compuesta por al menos 8 caracteres y estar conformada por al menos un a-z, A-Z y 0-9',
      es_sales:
        'La nueva contraseña debe de estar compuesta por al menos 8 caracteres y estar conformada por al menos un a-z, A-Z y 0-9',
      po: 'A nova senha deve ter pelo menos 8 caracteres e conter pelo menos um de a-z, A-Z e 0-9',
    },
    back_error: {
      en: 'Please try again',
      es: 'Por favor intenta de nuevo',
      es_sales: 'Por favor intenta de nuevo',
      po: 'Por favor, tente novamente',
    },
    must_match: {
      en: 'Passwords must match',
      es: 'Las contraseñas deben coincidir',
      es_sales: 'Las contraseñas deben coincidir',
      po: 'As senhas devem corresponder',
    },
    must_differ: {
      en: 'The new password must be different than the current one',
      es: 'La nueva contraseña debe de ser distinta a la actual',
      es_sales: 'La nueva contraseña debe de ser distinta a la actual',
      po: 'A nova senha deve ser diferente da atual',
    },
    rules_password: {
      en: 'The password must be 8 to 16 characters, contain at least 1 uppercase and 1 lowercase character, 1 digit and 1 special character.',
      es: 'La contraseña debe tener de 8 a 16 caracteres, contener al menos 1 carácter en mayúscula y 1 en minúscula, 1 dígito y 1 carácter especial.',
      es_sales:
        'La contraseña debe tener de 8 a 16 caracteres, contener al menos 1 carácter en mayúscula y 1 en minúscula, 1 dígito y 1 carácter especial.',
      po: 'A senha deve ter de 8 a 16 caracteres, conter pelo menos 1 maiúsculo e 1 minúsculo, 1 dígito e 1 caractere especial.',
    },
    required: {
      en: 'This value is required',
      es: 'Valor requerido',
      es_sales: 'Valor requerido',
      po: 'Este valor é obrigatório',
    },
  },
  design_modal: {
    title: {
      en: 'Drag & Drop',
      es: 'Arrastra y Suelta',
      es_sales: 'Arrastra y Suelta',
      po: 'Arraste e Solte',
    },
    reset_to_default_layout: {
      en: 'Do you want to reset to default layout?',
      es: '¿Quieres colocar el layout original?',
      es_sales: '¿Quieres colocar el layout original?',
      po: 'Quer colocar o formato original?',
    },
    confirm_yes: {
      en: 'Yes',
      es: 'Si',
      es_sales: 'Si',
      po: 'Sim',
    },
    confirm_no: {
      en: 'No',
      es: 'No',
      es_sales: 'No',
      po: 'não',
    },
  },
  settings_modal: {
    panel: {
      title: {
        en: 'Settings',
        es: 'Configuración',
        es_sales: 'Configuración',
        po: 'Configuração',
      },
      button_save_changes: {
        en: 'Save Changes (ESC)',
        es: 'Guardar Cambios (ESC)',
        es_sales: 'Guardar Cambios (ESC)',
        po: 'Guardar alterações (ESC)',
      },
      button_reset_computer: {
        en: 'Clear browser memory',
        es: 'Limpiar la memoria del navegador',
        es_sales: 'Limpiar la memoria del navegador',
        po: 'Limpar a memória do navegador',
      },
      confirm_reset: {
        en: 'Yes',
        es: 'Si',
        es_sales: 'Si',
        po: 'Sim',
      },
      decline_reset: {
        en: 'No',
        es: 'No',
        es_sales: 'No',
        po: 'Não',
      },
      reset_computer_to_default: {
        en: 'Are you sure you want the platform memory?',
        es: '¿Estás seguro de querer limpiar toda la memoria de la plataforma?',
        es_sales:
          '¿Estás seguro de querer limpiar toda la memoria de la plataforma?',
        po: 'Tem a certeza que quer limpar a memória da plataforma?',
      },
    },
    vwap_settings: {
      title: {
        en: 'VWAP Widget Settings',
        es: 'Configuración del panel VWAP',
        es_sales: 'Configuración del panel VWAP',
        po: 'VWAP Widget Settings',
      },
      label_order_type: {
        en: 'Order Type',
        es: 'Tipo de Orden',
        es_sales: 'Tipo de Orden',
        po: 'Tipo de ordem',
      },
      limit: {
        en: 'Limit',
        es: 'Límite',
        es_sales: 'Límite',
        po: 'Limite',
      },
      market: {
        en: 'Market',
        es: 'Mercado',
        es_sales: 'Mercado',
        po: 'Mercado',
      },
      tim_ico: {
        en: 'IOC - Instant or Cancel',
        es: 'IOC - Instant or Cancel',
        es_sales: 'IOC - Instant or Cancel',
        po: 'IOC Instant or Cancel',
      },
      tim_fok: {
        en: 'FOK - Fill Or Kill',
        es: 'FOK - Fill Or Kill',
        es_sales: 'FOK - Fill Or Kill',
        po: 'FOK - Fill Or Kill',
      },
      label_expiration: {
        en: 'Expiration',
        es: 'Vigencia',
        es_sales: 'Vigencia',
        po: 'Expiração',
      },
      span_seconds: {
        en: '(sec)',
        es: '(seg)',
        es_sales: '(seg)',
        po: '(seg)',
      },
      enable_double_click: {
        en: 'Enable double click',
        es: 'Habilitar doble click',
        es_sales: 'Habilitar doble click',
        po: 'Permitir duplo clique',
      },
      button_save_changes: {
        en: 'Save Changes',
        es: 'Guardar Cambios',
        es_sales: 'Guardar Cambios',
        po: 'Guardar Alterações',
      },
      label_initial_displayed_qty: {
        en: 'Initial amount to operate',
        es: 'Cantidad inicial a operar',
        es_sales: 'Cantidad inicial a operar',
        po: 'Quantidade inicial para operar',
      },
    },
    design_settings: {
      title: {
        en: 'Drag & Drop',
        es: 'Arrastra y Suelta',
        es_sales: 'Arrastra y Suelta',
        po: 'Arraste e solte',
      },
      reset_button: {
        en: 'Default Layout',
        es: 'Layout Original',
        es_sales: 'Layout Original',
        po: 'Layout Original',
      },
      reset_to_default_layout: {
        en: 'Do you want to reset to default layout?',
        es: '¿Quieres colocar el layout original?',
        es_sales: '¿Quieres colocar el layout original?',
        po: 'Queres colocar o layout original?',
      },
      confirm_yes: {
        en: 'Yes',
        es: 'Si',
        es_sales: 'Si',
        po: 'Sim',
      },
      confirm_no: {
        en: 'No',
        es: 'No',
        es_sales: 'No',
        po: 'Não',
      },
    },
    global_settings: {
      inverse_rate_notification: {
        en: 'Sound on Inverse Rates',
        es: 'Sonido en Precios Inversos',
        es_sales: 'Sonido en Precios Inversos',
        po: 'Sound on Inverse Rates',
      },
      clocks: {
        en: 'Show international clocks',
        es: 'Mostrar relojes internacionales',
        es_sales: 'Mostrar relojes internacionales',
        po: 'Mostrar relógios internacionais',
      },
      title: {
        en: 'Global Settings',
        es: 'Configuración Global',
        es_sales: 'Configuración Global',
        po: 'Configuração global',
      },
      title_config_viewrs: {
        en: 'Viewer Settings',
        es: 'Configuración de Observados',
        es_sales: 'Configuración de Observados',
        po: 'Configurações Observadas',
      },
      select_settings: {
        platform: {
          en: 'Platform',
          es: 'Plataforma',
          es_sales: 'Plataforma',
          po: 'Plataforma',
        },
        design: {
          en: 'Design',
          es: 'Diseño',
          es_sales: 'Diseño',
          po: 'Desenho',
        },
      },
      notification_initial_order: {
        en: 'Starting order notification',
        es: 'Notificación inicial de la orden',
        es_sales: 'Notificación inicial de la orden',
        po: 'Início da notificação das ordens',
      },
      sound: {
        en: 'Sound on Trade',
        es: 'Sonido en Operaciones',
        es_sales: 'Sonido en Operaciones',
        po: 'Som em Operações',
      },
      sound_viewer: {
        en: 'Sound on Viewers Trades',
        es: 'Sonido en Observados',
        es_sales: 'Sonido en Observados',
        po: 'Som em Observado',
      },
      notify_fixed_global: {
        en: 'Trade notification',
        es: 'Notificación de Operación',
        es_sales: 'Notificación de Operación',
        po: 'Notificação de Operação',
      },
      notify_fixed_viewer: {
        en: 'Viewers notifications',
        es: 'Notificación Observados',
        es_sales: 'Notificación Observados',
        po: 'Notificação Observada',
      },
      notify_fixed: {
        en: 'Permanent Viewers Notifications',
        es: 'Notificaciones Permanentes Observados',
        es_sales: 'Notificaciones Permanentes Observados',
        po: 'Notificações Permanentes Observadas',
      },
      label_time_expiration: {
        en: 'Expiration in IOC and FOK orders',
        es: 'Expiración de órdenes IOC y FOK',
        es_sales: 'Expiración de órdenes IOC y FOK',
        po: 'Expiração de ordens IOC e FOK',
      },
      span_seconds: {
        en: '(sec)',
        es: '(seg)',
        es_sales: '(seg)',
        po: '(seg)',
      },
      label_language: {
        en: 'Language',
        es: 'Lenguaje',
        es_sales: 'Lenguaje',
        po: 'Idioma',
      },
      label_theme: {
        en: 'Theme',
        es: 'Tema',
        es_sales: 'Tema',
        po: 'tema',
      },
      theme_blue: {
        en: 'Blue',
        es: 'Azul',
        es_sales: 'Azul',
        po: 'Azul',
      },
      theme_black: {
        en: 'Dark',
        es: 'Oscuro',
        es_sales: 'Oscuro',
        po: 'Sombrio',
      },
      theme_light: {
        en: 'Light Blue',
        es: 'Azul Ligero',
        es_sales: 'Azul Ligero',
        po: 'Azul Claro',
      },
    },
    slippage_settings: {
      title: {
        en: 'Slippage Settings (pips)',
        es: 'Tolerancia de Deslizamiento (pips)',
        es_sales: 'Tolerancia de Deslizamiento (pips)',
        po: 'Slippage Settings (pips)',
      },
      symbol: {
        en: 'Instrument',
        es: 'Instrumento',
        es_sales: 'Instrumento',
        po: 'Instrumento',
      },
      slippage_market: {
        en: 'Market',
        es: 'Mercado',
        es_sales: 'Mercado',
        po: 'Mercado',
      },
      slippage_limit: {
        en: 'Limit',
        es: 'Límite',
        es_sales: 'Límite',
        po: 'Limite',
      },
      type: {
        en: 'Type',
        es: 'Tipo',
        es_sales: 'Tipo',
        po: 'Tipo',
      },
    },
    err: {
      input_minus_zero: {
        en: 'Value is less than zero',
        es: 'El valor es menor que cero',
        es_sales: 'El valor es menor que cero',
        po: 'O valor é menor que zero',
      },
      input_has_decimal: {
        en: 'Value must be integer',
        es: 'Valor debe ser entero',
        es_sales: 'Valor debe ser entero',
        po: 'Valor deve ser inteiro',
      },
      input_greater_recommended: {
        en: 'Max value is 99 pips',
        es: 'Valor max. es de 99 pips',
        es_sales: 'Valor max. es de 99 pips',
        po: 'Valor max. é de 99 pips',
      },
    },
  },
  orderModal: {
    error_expiration_date: {
      en: 'The Expiration Date cannot be set during platform’s downtimes, please amend Expiration Date',
      es: 'La fecha de vencimiento no se puede establecer durante los tiempos de inactividad de la plataforma, modifique la fecha de vencimiento',
      es_sales:
        'La fecha de vencimiento no se puede establecer durante los tiempos de inactividad de la plataforma, modifique la fecha de vencimiento',
      po: 'A Data de Expiração não pode ser definida durante tempos de inatividade da plataforma, altere a Data de Expiração',
    },
    err_start_date_greater_than_expire_time: {
      en: 'Expire Time must be greater than Start Time',
      es: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
      es_sales: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
      po: 'O tempo de expiração deve ser maior que o tempo de início',
    },
    err_start_date: {
      en: 'Expire must be greater than Start Time',
      es: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
      es_sales: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
      po: 'A expiração deve ser maior que a Hora de início',
    },
    label_datetime_picker: {
      en: 'Expiration: Date / Time',
      es: 'Válido hasta: Fecha / Hora',
      es_sales: 'Válido hasta: Fecha / Hora',
      po: 'Válido até: Data / hora',
    },
    label_options: {
      en: 'Options',
      es: 'Opciones',
      es_sales: 'Opciones',
      po: ' opções',
    },
    text_or: {
      en: 'or',
      es: 'o',
      es_sales: 'o',
      po: 'ou',
    },
    order_1: {
      en: '1° Order',
      es: 'Orden 1',
      es_sales: 'Orden 1',
      po: 'Ordem 1',
    },
    order_2: {
      en: '2° Order',
      es: 'Orden 2',
      es_sales: 'Orden 2',
      po: 'Ordem 2',
    },
    label_expiration: {
      en: 'Expiration',
      es: 'Vigencia',
      es_sales: 'Vigencia',
      po: 'Expiração',
    },
    label_fixing_source: {
      en: 'Fixing Source',
      es: 'Fuente de Fijación',
      es_sales: 'Fuente de Fijación',
      po: 'fonte de fixação',
    },
    text_fixing_notifications: {
      closing: {
        en: 'Market Closing...',
        es: 'Market Closing...',
        es_sales: 'Market Closing...',
        po: 'Market Closing...',
      },
      closed: {
        en: 'Market Closed',
        es: 'Market Closed',
        es_sales: 'Market Closed',
        po: 'Market Closed',
      },
    },
    text_tif: {
      fok: {
        en: 'FOK - Fill or Cancel',
        es: 'FOK - Fill or Cancel',
        es_sales: 'FOK - Fill or Cancel',
        po: 'FOK - Fill or Cancel',
      },
      gtc: {
        en: 'GTC - Good Till Cancel',
        es: 'GTC - Good Till Cancel',
        es_sales: 'GTC - Good Till Cancel',
        po: 'GTC - Good Till Cancel',
      },
      gtt: {
        en: 'GTT - Good Till Time',
        es: 'GTT - Good Till Time',
        es_sales: 'GTT - Good Till Time',
        po: 'GTT - Good Till Time',
      },
      ioc: {
        en: 'IOC - Instant or Cancel',
        es: 'IOC - Instant or Cancel',
        es_sales: 'IOC - Instant or Cancel',
        po: 'IOC - Instant or Cancel',
      },
      session: {
        en: 'GIS - Good In Session',
        es: 'GIS - Good In Session',
        es_sales: 'GIS - Good In Session',
        po: 'GIS - Good In Session',
      },
    },
    label_direction: {
      en: 'Direction',
      es: 'Dirección',
      es_sales: 'Dirección',
      po: 'Direção ',
    },
    text_buy: {
      en: 'Buy',
      es: 'Compra',
      es_sales: 'B Venta',
      po: 'Compra',
    },
    text_sell: {
      en: 'Sell',
      es: 'Venta',
      es_sales: 'B Compra',
      po: 'Venda',
    },
    button_confirm: {
      en: 'Confirm',
      es: 'Confirmar',
      es_sales: 'Confirmar',
      po: 'Confirmar',
    },
    button_cancel: {
      en: 'Cancel',
      es: 'Cancelar',
      es_sales: 'Cancelar',
      po: 'Cancelar',
    },
    th_qty: {
      en: 'Amount',
      es: 'Cantidad',
      es_sales: 'Cantidad',
      po: 'Quantidade',
    },
    th_exchange_price: {
      en: 'Exchange Price',
      es: 'Tipo de Cambio',
      es_sales: 'Tipo de Cambio',
      po: 'Tipo de Câmbio',
    },
    label_interval: {
      en: 'Interval (sec)',
      es: 'Intervalo (seg)',
      es_sales: 'Intervalo (seg)',
      po: 'Intervalo (seg)',
    },
    label_at_market: {
      en: 'Market Price',
      es: 'Precio de Mercado',
      es_sales: 'Precio de Mercado',
      po: 'Preço de mercado',
    },
    advanced_strategy: {
      en: 'Advanced Strategy',
      es: 'Estrategia Avanzada',
      es_sales: 'Estrategia Avanzada',
      po: 'Estrategia Avançada',
    },
    title_buy: {
      en: 'I buy',
      es: 'Yo compro',
      es_sales: 'Banco Vende',
      po: 'Eu Compro ',
    },
    title_sell: {
      en: 'I sell',
      es: 'Yo vendo',
      es_sales: 'Banco Compra',
      po: 'Eu Vendo ',
    },
    expire_date: {
      en: 'Expiration Date',
      es: 'Válido hasta',
      es_sales: 'Válido hasta',
      po: 'Válido até',
    },
    start_date: {
      en: 'Starting Date',
      es: 'Fecha de Inicio',
      es_sales: 'Fecha de Inicio',
      po: 'Data de Início',
    },
  },
  modifyOrderModal: {
    title: {
      en: 'Modify Order',
      es: 'Modificar Orden',
      es_sales: 'Modificar Orden',
      po: 'Modificar Ordem',
    },
    confirm_button: {
      en: 'Confirm',
      es: 'Confirmar',
      es_sales: 'Confirmar',
      po: 'Confirmar',
    },
    cancel_button: {
      en: 'Cancel',
      es: 'Cancelar',
      es_sales: 'Cancelar',
      po: 'Cancelar',
    },
  },
  add_pair_modal: {
    title: {
      en: 'Add Pair',
      es: 'Agregar Par',
      es_sales: 'Agregar Par',
      po: 'Adicionar par',
    },
    label_pair: {
      en: 'Pair',
      es: 'Par',
      es_sales: 'Par',
      po: 'Incluir Par',
    },
    option_select_instrument: {
      en: 'Instrument',
      es: 'Instrumento',
      es_sales: 'Instrumento',
      po: 'Instrumento',
    },
    volumes_to_subscribe: {
      en: 'Volumes to subscribe',
      es: 'Volúmenes a suscribrse',
      es_sales: 'Volúmenes a suscribrse',
      po: 'Volumes para encrever-se',
    },
    button_confirm: {
      en: 'Confirm',
      es: 'Confirmar',
      es_sales: 'Confirmar',
      po: 'Confirmar',
    },
    button_cancel: {
      en: 'Cancel',
      es: 'Cancelar',
      es_sales: 'Cancelar',
      po: 'Cancelar',
    },
  },
};

export const order_fields = {
  toolpicks: {
    SLICER: {
      en: 'Places an order in blocks, each at n-price levels.',
      es: 'Posiciona una orden en bloques, cada uno a n-niveles de precio.',
      es_sales:
        'Posiciona una orden en bloques, cada uno a n-niveles de precio.',
      po: 'Posicionar uma ordem em bloques, cada um com o seu nivel de preço',
    },
    ICEBERG: {
      en: 'Place an order, revealing your position in fractions.',
      es: 'Posiciona una orden, revelando en fracciones tu posición.',
      es_sales: 'Posiciona una orden, revelando en fracciones tu posición.',
      po: 'Posicione uma ordem, revelando sua posição em frações',
    },
    LIMIT: {
      en: 'Places an order up to a limit price or better.',
      es: 'Posiciona una orden hasta un precio límite o mejor.',
      es_sales: 'Posiciona una orden hasta un precio límite o mejor.',
      po: 'Posicione uma orden até ao preço limite ou melhor',
    },
    MARKET: {
      en: 'Places a direct order at the market price.',
      es: 'Posiciona una orden directo al precio del mercado.',
      es_sales: 'Posiciona una orden directo al precio del mercado.',
      po: 'Coloque uma ordem direta ao preço de mercado',
    },
    STOP_MARKET: {
      en: 'Places an order at the current price, after reaching the Stop level.',
      es: 'Posiciona una orden al precio actual, tras alcanzar el nivel Stop.',
      es_sales:
        'Posiciona una orden al precio actual, tras alcanzar el nivel Stop.',
      po: 'Coloque uma ordem no preço atual, depois de atingir o nível de STOP',
    },
    STOP_LIMIT: {
      en: 'Places an order up to a limit price or better, after reaching the Stop level.',
      es: 'Posiciona una orden hasta un precio límite o mejor, tras alcanzar el nivel Stop.',
      es_sales:
        'Posiciona una orden hasta un precio límite o mejor, tras alcanzar el nivel Stop.',
      po: 'Faça uma ordem até um preço limite ou melhor, depois de atingir o nível de STOP',
    },
    MAN_OFFSET: {
      en: '',
      es: '',
      es_sales: '',
      po: '',
    },
    OCO: {
      en: '',
      es: '',
      es_sales: '',
      po: '',
    },
    FIXING: {
      en: '',
      es: '',
      es_sales: '',
      po: '',
    },
    PEGG: {
      en: '',
      es: '',
      es_sales: '',
      po: '',
    },
    PEGGTAKETOP: {
      en: '',
      es: '',
      es_sales: '',
      po: '',
    },
    PB: {
      en: '',
      es: '',
      es_sales: '',
      po: '',
    },
    COPYCAT: {
      en: '',
      es: '',
      es_sales: '',
      po: '',
    },
  },
  order: {
    error_expiration_date: {
      en: 'The Expiration Date cannot be set during platform’s downtimes, please amend Expiration Date',
      es: 'La fecha de vencimiento no se puede establecer durante los tiempos de inactividad de la plataforma, modifique la fecha de vencimiento',
      es_sales:
        'La fecha de vencimiento no se puede establecer durante los tiempos de inactividad de la plataforma, modifique la fecha de vencimiento',
      po: 'A Data de Expiração não pode ser definida durante tempos de inatividade da plataforma, altere a Data de Expiração',
    },
    market: {
      en: 'Market',
      es: 'Mercado',
      es_sales: 'Mercado',
      po: 'Market',
    },
    limit: {
      en: 'Limit',
      es: 'Límite',
      es_sales: 'Límite',
      po: 'Limit',
    },
    stop_market: {
      en: 'Stop Loss - Market',
      es: 'Stop Loss - Mercado',
      es_sales: 'Stop Loss - Mercado',
      po: 'Stop Loss - Market',
    },
    stop_limit: {
      en: 'Stop Loss - Limit',
      es: 'Stop Loss - Límite',
      es_sales: 'Stop Loss - Límite',
      po: 'Stop Loss- Limit',
    },
    iceberg: {
      en: 'Iceberg',
      es: 'Iceberg',
      es_sales: 'Iceberg',
      po: 'Iceberg',
    },
    slicer: {
      en: 'Slicer',
      es: 'Escalonada',
      es_sales: 'Escalonada',
      po: 'Slicer',
    },
    fixing: {
      en: 'Fixing',
      es: 'Fijación',
      es_sales: 'Fijación',
      po: 'Fixing',
    },
    oco: {
      en: 'OCO',
      es: 'OCO',
      es_sales: 'OCO',
      po: 'OCO',
    },
    pegg: {
      en: 'Market Maker',
      es: 'Formador de Mercado',
      es_sales: 'Formador de Mercado',
      po: 'Formador de Mercado',
    },
    peggtaketop: {
      en: 'Maker Take Top',
      es: 'Maker Take Top',
      es_sales: 'Maker Take Top',
      po: 'Maker Take Top',
    },
    copycat: {
      en: 'COPYCAT',
      es: 'COPYCAT',
      es_sales: 'COPYCAT',
      po: 'COPYCAT',
    },
    man_offset: {
      en: 'Man Offset',
      es: 'Man Offset',
      es_sales: 'Man Offset',
      po: 'Man Offset',
    },
  },
  title: {
    en: 'Order Type ',
    es: 'Tipo de Orden ',
    es_sales: 'Tipo de Orden ',
    po: 'Tipo de ordem ',
  },
};

export const orders = {
  status: {
    NEW: {
      en: 'Active',
      es: 'Activo',
      es_sales: 'Activo',
      po: 'Ativo',
    },
    FILLED: {
      en: 'Filled',
      es: 'Completado',
      es_sales: 'LLenado',
      po: 'Completado',
    },
    PARTIALLY_FILLED: {
      en: 'Partially Filled',
      es: 'Llenado Parcial',
      es_sales: 'Llenado Parcial',
      po: 'Parcialmente',
    },
    CANCELED: {
      en: 'Canceled',
      es: 'Cancelado',
      es_sales: 'Cancelado',
      po: 'Cancelado',
    },
    REJECTED: {
      en: 'Rejected',
      es: 'Rechazado',
      es_sales: 'Rechazado',
      po: 'Rejeitado',
    },
    EXPIRED: {
      en: 'Expired',
      es: 'Cancelado',
      es_sales: 'Cancelado',
      po: 'Expirado',
    },
    UNKNOWN: {
      en: 'Unknown',
      es: 'Desconocido',
      es_sales: 'Desconocido',
      po: 'Desconhecido',
    },
    PENDING_CANCEL: {
      en: 'Canceling',
      es: 'Cancelando',
      es_sales: 'Cancelando',
      po: 'Cancelamento',
    },
    PENDING_NEW: {
      en: 'Pending',
      es: 'Pendiente',
      es_sales: 'Pendiente',
      po: 'Pending',
    },
    ACTIVE: {
      en: 'Active',
      es: 'Activo',
      es_sales: 'Activo',
      po: 'Ativo',
    },
    ACCEPTED: {
      en: 'Accepted',
      es: 'Aceptada',
      es_sales: 'Aceptada',
      po: 'Aceito',
    },
  },
  order_type: {
    LIMIT: {
      en: 'Limit',
      es: 'Límite',
      es_sales: 'Límite',
      po: 'Limit',
    },
    MARKET: {
      en: 'Market',
      es: 'Mercado',
      es_sales: 'Mercado',
      po: 'Mercado',
    },
    STOP_MARKET: {
      en: 'Stop with Market',
      es: 'Cierre de mercado',
      es_sales: 'Cierre de mercado',
      po: 'Stop with Market',
    },
    STOP_LIMIT: {
      en: 'Stop with Limit',
      es: 'Parada con Límite',
      es_sales: 'Parada con Límite',
      po: 'Stop with Limit',
    },
    PB: {
      en: 'Switch',
      es: 'Switch',
      es_sales: 'Switch',
      po: 'Switch',
    },
    ICEBERG: {
      en: 'Iceberg',
      es: 'Iceberg',
      es_sales: 'Iceberg',
      po: 'Iceberg',
    },
    SLICER: {
      en: 'Stepped',
      es: 'Escalonada',
      es_sales: 'Escalonada',
      po: 'Stepped',
    },
    FIXING: {
      en: 'Fixing',
      es: 'Fijar',
      es_sales: 'Fijar',
      po: 'Fixing',
    },
    OCO: {
      en: 'OCO',
      es: 'OCO',
      es_sales: 'OCO',
      po: 'OCO',
    },
    PEGG: {
      en: 'Market Maker',
      es: 'Formador de Mercado',
      es_sales: 'Formador de Mercado',
      po: 'Market Maker',
    },
    PEGGTAKETOP: {
      en: 'Maker Take Top',
      es: 'Maker Take Top',
      es_sales: 'Maker Take Top',
      po: 'Maker Take Top',
    },
    COPYCAT: {
      en: 'COPYCAT',
      es: 'COPYCAT',
      es_sales: 'COPYCAT',
      po: 'COPYCAT',
    },
    MAN_OFFSET: {
      en: 'Limit',
      es: 'Límite',
      es_sales: 'Límite',
      po: 'Limit',
    },
  },
  side: {
    BUY: {
      en: 'Buy',
      es: 'Compra',
      es_sales: 'B Venta',
      po: 'Compra',
    },
    SELL: {
      en: 'Sell',
      es: 'Venta',
      es_sales: 'B Compra',
      po: 'Venda',
    },
  },
  order_modal: {
    err_start_date_greater_than_expire_time: {
      en: 'Expire Time must be greater than Start Time',
      es: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
      es_sales: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
      po: 'O tempo de expiração deve ser maior que o tempo de início',
    },
    advanced_strategy: {
      type: 'checkbox',
      label: {
        en: 'Advanced Strategy',
        es: 'Estrategia Avanzada',
        es_sales: 'Estrategia Avanzada',
        po: 'Estratégia Avançada',
      },
    },
    slicer_decimals: {
      type: 'number',
      label: {
        en: 'Difference between steps',
        es: 'Diferencia de precio entre escalón',
        es_sales: 'Diferencia de precio entre escalón',
        po: 'Diferença de preço entre as etapas',
      },
      placeholder: {
        en: 'Ex .00010',
        es: 'Ej .00010',
        es_sales: 'Ej .00010',
        po: 'Ex .00010',
      },
    },
    slicer_qty: {
      type: 'text',
      label: {
        en: 'Slicer Amount',
        es: 'Importe Escalonado',
        es_sales: 'Importe Escalonado',
        po: 'Slicer Amount',
      },
      placeholder: {
        en: 'Step Amount',
        es: 'Importe por escalón',
        es_sales: 'Importe por escalón',
        po: 'Step Amount',
      },
    },
    slicer_interval: {
      type: 'number',
      label: {
        en: 'Buy/sell interval',
        es: 'Intervalo compra/venta',
        es_sales: 'Intervalo B compra/venta',
        po: 'Intervalo Compra/Venda',
      },
      placeholder: {
        en: 'seconds',
        es: 'segundos',
        es_sales: 'segundos',
        po: 'Segundos',
      },
    },
    price: {
      type: 'number',
      label: {
        en: 'Exchange Rate',
        es: 'Tipo de Cambio',
        es_sales: 'Tipo de Cambio',
        po: 'Taxa de câmbio',
      },
      // MAR instruments
      // https://visionfx.atlassian.net/browse/IPS-149
      // https://visionfx.atlassian.net/browse/IPS-158
      label_price_mar: {
        en: 'Forward Points',
        es: 'Puntos de Avance',
        es_sales: 'Puntos de Avance',
        po: 'Pontos para Frente',
      },
      label_stop_limit: {
        en: 'Stop Limit Rate',
        es: 'Tipo de Cambio ',
        es_sales: 'Tipo de Cambio ',
        po: 'Taxa de câmbio',
      },
      label_OCO_buy: {
        en: 'Limit price',
        es: 'Precio Limite',
        es_sales: 'Precio Limite',
        po: 'Limite de Compra',
      },
      label_OCO_sell: {
        en: 'Limit price',
        es: 'Precio Limite',
        es_sales: 'Precio Limite',
        po: 'Limite de Venda',
      },
      placeholder: {
        en: 'Order Rate',
        es: 'TC de la orden',
        es_sales: 'TC de la orden',
        po: 'Taxa do pedido',
      },
    },
    take_profit: {
      type: 'number',
      label: {
        en: 'Take Profit',
        es: 'Tomar Ganancia',
        es_sales: 'Tomar Ganancia',
        po: 'Take Profit',
      },
      placeholder: {
        en: 'Trigger rate',
        es: 'TC para activar la orden',
        es_sales: 'TC para activar la orden',
        po: 'Trigger rate',
      },
    },
    stop_price: {
      type: 'number',
      label: {
        en: 'Stop Price',
        es: 'Stop Price ',
        es_sales: 'Stop Price',
        po: 'Stop Price',
      },
      label_stop_limit: {
        en: 'Stop Trigger Rate',
        es: 'Stop Trigger Rate ',
        es_sales: 'Stop Trigger Rate',
        po: 'Stop Trigger Rate',
      },
      label_OCO_buy: {
        en: 'Market Stop Loss',
        es: 'Stop loss - Mercado',
        es_sales: 'Stop loss - Mercado',
        po: 'Stop loss - Mercado',
      },
      label_OCO_sell: {
        en: 'Market Stop Loss',
        es: 'Stop loss - Mercado',
        es_sales: 'Stop loss - Mercado',
        po: 'Stop loss - Mercado',
      },
      placeholder: {
        en: 'Trigger rate',
        es: 'TC para activar la orden',
        es_sales: 'TC para activar la orden',
        po: 'Trigger rate',
      },
    },
    qty: {
      type: 'text',
      label: {
        en: 'Total Amount',
        es: 'Importe total ',
        es_sales: 'Importe total ',
        po: 'Quantidade Total',
      },
      placeholder: {
        en: 'Quantity',
        es: 'Cantidad',
        es_sales: 'Cantidad',
        po: 'Quantidade',
      },
    },
    iceberg_qty: {
      type: 'text',
      label: {
        en: 'Amount to show',
        es: 'Importe a mostrar ',
        es_sales: 'Importe a mostrar ',
        po: 'Quantidade a mostrar',
      },
      placeholder: {
        en: 'Amount to show',
        es: 'cantidad a mostrar',
        es_sales: 'cantidad a mostrar',
        po: 'Quantidade a mostrar',
      },
    },
    client_note: {
      label: {
        en: 'Note',
        es: 'Nota',
        es_sales: 'Nota',
        po: 'Nota',
      },
    },
  },
  button_confirm: {
    en: 'Confirm',
    es: 'Confirmar',
    es_sales: 'Confirmar',
    po: 'Confirmar',
  },
  button_cancel: {
    en: 'Cancel',
    es: 'Cancelar',
    es_sales: 'Cancelar',
    po: 'Cancelar',
  },
};

export const trading_widget = {
  fl_title: flexLayout.trading,
  sell: {
    en: 'Sell',
    es: 'Vender',
    es_sales: 'Vender',
    po: 'Vender',
  },
  buy: {
    en: 'Buy',
    es: 'Comprar',
    es_sales: 'Comprar',
    po: 'Comprar',
  },
  btnTblToggleHide: {
    en: 'Hide records',
    es: 'Ocultar registros',
    es_sales: 'Ocultar registros',
    po: 'Ocultar registros',
  },
  btnTblToggleShow: {
    en: 'Show records',
    es: 'Mostrar registros',
    es_sales: 'Mostrar registros',
    po: 'Mostrar registros',
  },
  title: {
    en: 'Trading Widget',
    es: 'Libro completo',
    es_sales: 'Libro completo',
    po: 'Painel de Negociação',
  },
  select_default_value: {
    en: 'Instrument',
    es: 'Instrumento',
    es_sales: 'Instrumento',
    po: 'Instrumento',
  },
  config_title: {
    en: 'Trading Widget Settings',
    es: 'Configuración del panel Ejecución',
    es_sales: 'Configuración del panel Ejecución',
    po: 'Configurações do painel de Comercialização',
  },
  show_sources: {
    en: 'Show sources',
    es: 'Mostrar fuentes',
    es_sales: 'Mostrar fuentes',
    po: 'Mostrar fontes',
  },
  volume: {
    en: 'Vol',
    es: 'Vol',
    es_sales: 'Vol',
    po: 'Vol',
  },
  spread: {
    en: 'Spread',
    es: 'Margen',
    es_sales: 'Margen',
    po: 'Márgem',
  },
  source: {
    en: 'Source',
    es: 'Fuente',
    es_sales: 'Fuente',
    po: 'Fonte',
  },
  market: {
    en: 'Market',
    es: 'Mercado',
    es_sales: 'Mercado',
    po: 'Mercado',
  },
  limit: {
    en: 'Limit',
    es: 'Límite',
    es_sales: 'Límite',
    po: 'Limite',
  },
  order_type: {
    en: 'Order type',
    es: 'Tipo de orden',
    es_sales: 'Tipo de orden',
    po: 'Tipo de ordem',
  },
  enable_double_click: {
    en: 'Enable double click',
    es: 'Habilitar doble click',
    es_sales: 'Habilitar doble click',
    po: 'Ativar duplo clique',
  },
  aggregate_prices: {
    en: 'Consolidate orders volume at same rate',
    es: 'Consolidar importe de órdenes al mismo precio',
    es_sales: 'Consolidar importe de órdenes al mismo precio',
    po: 'Consolidar quantidade de ordens pelo mesmo preço',
  },
  label_expiration: {
    en: 'Fill or Kill FOK',
    es: 'Todo o Nada FOK',
    es_sales: 'Todo o Nada FOK',
    po: 'Fill or Kill FOK',
  },
  button_save_changes: {
    en: 'Save Changes',
    es: 'Guardar Cambios',
    es_sales: 'Guardar Cambios',
    po: 'Guardar alterações',
  },
  instrument: {
    en: 'Instrument',
    es: 'Instrumento',
    es_sales: 'Instrumento',
    po: 'Ativo',
  },
  settle_date: {
    en: 'Settle Date',
    es: 'Fecha Valor',
    es_sales: 'Fecha Valor',
    po: 'Data Valor',
  },
  fixing_date: {
    en: 'Fixing Date',
    es: 'Fecha Fixing',
    es_sales: 'Fecha Fixing',
    po: 'Fixing Date',
  },
  MKT: {
    en: 'Market',
    es: 'Mercado',
    es_sales: 'Mercado',
    po: 'Mercado',
  },
  TOP: {
    en: 'TOP',
    es: 'TOP',
    es_sales: 'TOP',
    po: 'TOP',
  },
  IOC: {
    en: 'VWAP',
    es: 'VWAP',
    es_sales: 'VWAP',
    po: 'VWAP',
  },
  FOK: {
    en: 'FOK',
    es: 'TODO',
    es_sales: 'TODO',
    po: 'FOK',
  },
  VDEPTH: {
    en: 'VDEPTH',
    es: 'VDEPTH',
    es_sales: 'VDEPTH',
    po: 'VDEPTH',
  },
  show_peggs: {
    en: 'Show own rates in Top buttons',
    es: 'Mostrar mis precios en botones Top',
    es_sales: 'Mostrar mis precios en botones Top',
    po: 'Mostrar meus preços nos botões de topo',
  },
  tradable_levels: {
    en: 'Enable trading of the lower levels',
    es: 'Habilitar la ejecución de los niveles inferiores',
    es_sales: 'Habilitar la ejecución de los niveles inferiores',
    po: 'Ativar a comercialização dos níveis mais baixos',
  },
  oco_checkbox: {
    en: 'Enable Auto-OCO',
    es: 'Habilitar Auto-OCO',
    es_sales: 'Habilitar Auto-OCO',
    po: 'Ativar Auto-OCO',
  },
  use_only_input_amount: {
    en: 'Enable to always send the input amount',
    es: 'Habilitar para enviar siempre el monto ingresado',
    es_sales: 'Habilitar para enviar siempre el monto ingresado',
    po: 'Ative para enviar sempre o valor de entrada',
  },
  show_trading_buttons_bid: {
    en: 'Bid',
    es: 'Demandas',
    es_sales: 'Demandas',
    po: 'Bid',
  },
  show_trading_buttons_offer: {
    en: 'Offer',
    es: 'Ofertas',
    es_sales: 'Ofertas',
    po: 'Offer',
  },
};

export const multiCurrency_widget = {
  common,
  fl_title: flexLayout.multiCurrency,
  title: {
    en: 'Multicurrency Widget',
    es: 'Multicurrency Widget',
    es_sales: 'Multicurrency Widget',
    po: 'Painel de Multi Moeda',
  },
  fracs_ladder: {
    en: 'Fracs on Ladder',
    es: 'Fracs en Escalera',
    es_sales: 'Fracs en Escalera',
    po: 'Fracs on Ladder',
  },
  name: {
    en: 'Multicurrency',
    es: 'Ejecución Multimoneda',
    es_sales: 'Ejecución Multimoneda',
    po: 'Operação Multi Moeda',
  },
  amount_type: {
    en: 'Set custom amount',
    es: 'Definir cantidad',
    es_sales: 'Definir cantidad',
    po: 'Definir montante',
  },
  config_title: {
    en: 'Multicurrency Widget Settings',
    es: 'Configuración del panel Ejecución Multimoneda',
    es_sales: 'Configuración del panel Ejecución Multimoneda',
    po: 'Configuração do painel Multi Moeda',
  },
  enable_double_click: {
    en: 'Enable double click',
    es: 'Habilitar doble click',
    es_sales: 'Habilitar doble click',
    po: 'Ativar duplo clique',
  },
  show_position: {
    en: 'Show current position',
    es: 'Mostrar posicion actual',
    es_sales: 'Mostrar posicion actual',
    po: 'Mostrar posição atual',
  },
  show_joins: {
    en: 'Show join buttons',
    es: 'Mostrar botones join',
    es_sales: 'Mostrar botones join',
    po: 'Mostrar botões join',
  },
  show_mids: {
    en: 'Show mid buttons',
    es: 'Mostrar botones mid',
    es_sales: 'Mostrar botones mid',
    po: 'Mostrar botões mid',
  },
  show_ladder: {
    en: 'Show ladder buttons',
    es: 'Mostrar botones ladder',
    es_sales: 'Mostrar botones ladder',
    po: 'Mostrar botões ladder',
  },
  show_presets: {
    en: 'Show Box Presets',
    es: 'Mostrar presets en cajas',
    es_sales: 'Mostrar presets en cajas',
    po: 'Mostrar Box Preset',
  },
  toogle_hot_presets: {
    en: 'Enable hot presets',
    es: 'Presets rapidos',
    es_sales: 'Presets rapidos',
    po: 'Habilitar Hot Presets',
  },
  oco_checkbox: {
    en: 'Enable Auto-OCO',
    es: 'Habilitar Auto-OCO',
    es_sales: 'Habilitar Auto-OCO',
    po: 'Ativar Auto-OCO',
  },
  show_bid_offer: {
    en: 'Show Bid/Offer buttons',
    es: 'Mostrar botones Demandas/Ofertas',
    es_sales: 'Mostrar botones Demandas/Ofertas',
    po: 'Show Bid/Offer buttons',
  },
  add_panel: {
    en: 'Add panel',
    es: 'Añadir panel',
    es_sales: 'Añadir panel',
    po: 'Adicionar painel',
  },
  select_instrument: {
    en: 'Please select an instrument...',
    es: 'Selecciona un instrumento...',
    es_sales: 'Selecciona un instrumento...',
    po: 'Por favor, selecione um instrumento...',
  },
  apply_global_amounts: {
    en: 'Customize',
    es: 'Personalizar',
    es_sales: 'Personalizar',
    po: 'Customizar',
  },
};

export const rfs_widget = {
  common,
  fl_title: flexLayout.rfs,
  title: {
    en: 'Streaming FRWD & SWAPS Widget',
    es: 'Widget de transmisión de FRWD y SWAPS',
    es_sales: 'Widget de transmisión de FRWD y SWAPS',
    po: 'Widget de streaming FRWD e SWAPS',
  },
  fracs_ladder: {
    en: 'Fracs on Ladder',
    es: 'Fracs en Escalera',
    es_sales: 'Fracs en Escalera',
    po: 'Fracs on Ladder',
  },
  name: {
    en: 'Streaming FRWD & SWAPS',
    es: 'Transmisión de FRWD y SWAPS',
    es_sales: 'Transmisión de FRWD y SWAPS',
    po: 'Streaming FRWD e SWAPS',
  },
  amount_type: {
    en: 'Set custom amount',
    es: 'Definir cantidad',
    es_sales: 'Definir cantidad',
    po: 'Definir montante',
  },
  config_title: {
    en: 'Streaming FRWD & SWAPS Widget Settings',
    es: 'Configuración de widgets de transmisión de FRWD y SWAPS',
    es_sales: 'Configuración de widgets de transmisión de FRWD y SWAPS',
    po: 'Configurações de widget de streaming FRWD e SWAPS',
  },
  enable_double_click: {
    en: 'Enable double click',
    es: 'Habilitar doble click',
    es_sales: 'Habilitar doble click',
    po: 'Ativar duplo clique',
  },
  show_position: {
    en: 'Show current position',
    es: 'Mostrar posicion actual',
    es_sales: 'Mostrar posicion actual',
    po: 'Mostrar posição atual',
  },
  show_joins: {
    en: 'Show join buttons',
    es: 'Mostrar botones join',
    es_sales: 'Mostrar botones join',
    po: 'Mostrar botões join',
  },
  show_ladder: {
    en: 'Show ladder buttons',
    es: 'Mostrar botones ladder',
    es_sales: 'Mostrar botones ladder',
    po: 'Mostrar botões ladder',
  },
  show_presets: {
    en: 'Show Box Presets',
    es: 'Mostrar presets en cajas',
    es_sales: 'Mostrar presets en cajas',
    po: 'Mostrar Box Preset',
  },
  toogle_hot_presets: {
    en: 'Enable hot presets',
    es: 'Presets rapidos',
    es_sales: 'Presets rapidos',
    po: 'Habilitar Hot Presets',
  },
  oco_checkbox: {
    en: 'Enable Auto-OCO',
    es: 'Habilitar Auto-OCO',
    es_sales: 'Habilitar Auto-OCO',
    po: 'Ativar Auto-OCO',
  },
  show_bid_offer: {
    en: 'Show Bid/Offer buttons',
    es: 'Mostrar botones Demandas/Ofertas',
    es_sales: 'Mostrar botones Demandas/Ofertas',
    po: 'Show Bid/Offer buttons',
  },
  add_panel: {
    en: 'Add panel',
    es: 'Añadir panel',
    es_sales: 'Añadir panel',
    po: 'Adicionar painel',
  },
  select_instrument: {
    en: 'Please select an instrument...',
    es: 'Selecciona un instrumento...',
    es_sales: 'Selecciona un instrumento...',
    po: 'Por favor, selecione um instrumento...',
  },
  select_default_rfs_type: {
    en: 'Outright',
    es: 'Outright',
    es_sales: 'Outright',
    po: 'Outright',
  },
  publish_date: {
    en: 'Publish Date',
    es: 'Observado',
    es_sales: 'Observado',
    po: 'Publish Date',
  },
  publish_maturity_date: {
    en: 'Publish / Maturity Date',
    es: 'Publicación / Vencimiento',
    es_sales: 'Publicación / Vencimiento',
    po: 'Publish / Maturity Date',
  },
  apply_global_amounts: {
    en: 'Customize',
    es: 'Personalizar',
    es_sales: 'Personalizar',
    po: 'Customizar',
  },
};

export const peg_widget = {
  fl_title: flexLayout.pegg,
  validate_min_amount_buy: {
    en: 'The minimum amount cannot be greater than the Purchase amount',
    es: 'El monto mínimo no puede ser mayor que el monto de Compra',
    es_sales: 'El monto mínimo no puede ser mayor que el monto de Compra',
    po: 'A quantidade mínima não pode ser maior que a quantidade da compra',
  },
  validate_min_amount_sell: {
    en: 'The minimum amount cannot be greater than the Sale amount',
    es: 'El monto mínimo no puede ser mayor que el monto de Venta',
    es_sales: 'El monto mínimo no puede ser mayor que el monto de Venta',
    po: 'O valor mínimo não pode ser maior que o valor da Venda',
  },
  paused_msg: {
    en: 'Price out of range',
    es: 'Precio fuera de rango',
    es_sales: 'Precio fuera de rango',
    po: 'Preço fora de alcançe',
  },
  ref_price: {
    en: 'Reference Rate',
    es: 'Tasa de Referencia',
    es_sales: 'Tasa de Referencia',
    po: 'Taxa de referência',
  },
  button_cancel_all: {
    en: 'Cancel All',
    es: 'Cancelar Todo',
    es_sales: 'Cancelar Todo',
    po: 'Cancelar tudo',
  },
  value_date: {
    en: 'Value date',
    es: 'Fecha valor',
    es_sales: 'Fecha valor',
    po: 'Data de Validade',
  },
  fixing_date: {
    en: 'Fixing date',
    es: 'Fecha Fixing',
    es_sales: 'Fecha Fixing',
    po: 'Data de Fixação',
  },
  price_limits: {
    en: 'Price limits',
    es: 'Precios Límite',
    es_sales: 'Precios Límite',
    po: 'Preços Limite',
  },
  fwd_points: {
    en: 'FWD Points',
    es: 'Puntos FWD',
    es_sales: 'Puntos FWD',
    po: 'Pontos FWD',
  },
  tbo: {
    en: 'Min time between orders',
    es: 'Tiempo mínimo entre ordenes',
    es_sales: 'Tiempo mínimo entre ordenes',
    po: 'Tempo mínimo entre ordems',
  },
  duration_time: {
    en: 'Reference Rate Lifespan',
    es: 'Vida útil de la tasa de referencia',
    es_sales: 'Vida útil de la tasa de referencia',
    po: 'Vida útil da taxa de referência',
  },
  sec: {
    en: 'Seconds',
    es: 'Segundos',
    es_sales: 'Segundos',
    po: 'Segundos',
  },
  qtys: {
    en: 'Amount',
    es: 'Importe',
    es_sales: 'Importe',
    po: 'Quantidade',
  },
  spreads: {
    en: 'Spread',
    es: 'Márgen',
    es_sales: 'Márgen',
    po: 'Márgem',
  },
  min_amount: {
    en: 'Minimum amount',
    es: 'Cantidad mínima',
    es_sales: 'Cantidad mínima',
    po: 'Quantidade mínima',
  },
  expiration_time: {
    en: 'Expire Partially',
    es: 'Vencimiento de ordenes',
    es_sales: 'Vencimiento de ordenes',
    po: 'Expirar ordems',
  },
  expiration_time_br: {
    en: 'Filled Orders',
    es: 'parcialmente llenados',
    es_sales: 'parcialmente llenados',
    po: 'Filled Orders',
  },
  automatically_replenish: {
    en: 'Automatically replenish liquidity',
    es: 'Reponer liquidez automáticamente',
    es_sales: 'Reponer liquidez automáticamente',
    po: 'Reabastecer automaticamente a liquidez',
  },
  toggle_expiration_time: {
    en: 'Toggle expiration time',
    es: 'Activar tiempo de expiración',
    es_sales: 'Activar tiempo de expiración',
    po: 'Ativar tempo de expiração',
  },
  max_amount: {
    en: 'Max Amount',
    es: 'Importe Max',
    es_sales: 'Importe Max',
    po: 'Montante Máximo',
  },
  min_difference: {
    en: 'Min Difference',
    es: 'Diferencia Min',
    es_sales: 'Diferencia Min',
    po: 'Diferença Mín',
  },
  activate: {
    en: 'Activate',
    es: 'Activar',
    es_sales: 'Activar',
    po: 'Ativar',
  },
  take_bid: {
    en: 'Hit Bid',
    es: 'Hit Bid',
    es_sales: 'Hit Bid',
    po: 'Hit Bid',
  },
  take_offer: {
    en: 'Take Offer',
    es: 'Take Offer',
    es_sales: 'Take Offer',
    po: 'Take Offer',
  },
};

export const pnl_widget = {
  common,
  fl_title: flexLayout.pnl2,
  title: {
    en: 'Profit & Loss',
    es: 'Pérdidas y ganancias',
    es_sales: 'Pérdidas y ganancias',
    po: 'Lucro e Perda',
  },
  today: {
    en: 'Today',
    es: 'Hoy',
    es_sales: 'Hoy',
    po: 'Hoje',
  },
  yesterday: {
    en: 'Yesterday',
    es: 'Ayer',
    es_sales: 'Ayer',
    po: 'Ontem',
  },
  reset_time: {
    en: 'Reset Time EST',
    es: 'Hora de Reinicio EST',
    es_sales: 'Hora de Reinicio EST',
    po: 'Reset Time EST',
  },
  config_title: {
    en: 'Profit & Loss Widget Settings',
    es: 'Pérdidas & Ganancias Ajustes',
    es_sales: 'Pérdidas & Ganancias Ajustes',
    po: 'Lucro & Perda Definições',
  },
  grouping_pair: {
    en: 'Group by pair',
    es: 'Agrupar por par',
    es_sales: 'Agrupar por par',
    po: 'Grupo por par',
  },
  show_term_amount: {
    en: 'Show Term Amount Volumes',
    es: 'Mostrar volúmenes de cantidad a plazo',
    es_sales: 'Mostrar volúmenes de cantidad a plazo',
    po: 'Mostrar Volumes de Quantidade de Prazo',
  },
  button_save_changes: {
    en: 'Save Changes',
    es: 'Guardar Cambios',
    es_sales: 'Guardar Cambios',
    po: 'Guardar alterações',
  },
  week: {
    en: 'Week',
    es: 'Semana',
    es_sales: 'Semana',
    po: 'Semana',
  },
  month: {
    en: 'Month',
    es: 'Mes',
    es_sales: 'Mes',
    po: 'Mês',
  },
  custom: {
    en: 'Custom',
    es: 'Personalizados',
    es_sales: 'Personalizados',
    po: 'Personalizado',
  },
  market_price: {
    en: 'Market Price',
    es: 'Precio Mercado',
    es_sales: 'Precio Mercado',
    po: 'Market Price',
  },
  vwap_price: {
    en: 'Position Rate',
    es: 'Precio de Posición',
    es_sales: 'Precio de Posición',
    po: 'Preço de Posição',
  },
  total_buys: {
    en: 'Total Buys',
    es: 'Total de Compras',
    es_sales: 'Total de B ventas',
    po: 'Total de Compras',
  },
  total_sells: {
    en: 'Total Sells',
    es: 'Total de Ventas',
    es_sales: 'Total de B Compras',
    po: 'Total de Vendas',
  },
  total_buys_price: {
    en: 'Rate Buys',
    es: 'Precio de Compras',
    es_sales: 'Precio de B ventas',
    po: 'Preço de Compras',
  },
  total_sells_price: {
    en: 'Rate Sells',
    es: 'Precio de Ventas',
    es_sales: 'Precio de B Compras',
    po: 'Preço de Vendas',
  },
  total_pnl: {
    en: 'Total P&L',
    es: 'Total P&L',
    es_sales: 'Total P&L',
    po: 'Total P&L',
  },
  side: {
    en: 'B/S',
    es: 'C/V',
    es_sales: 'C/V',
    po: 'C/V',
  },
  realized: {
    en: 'Realized',
    es: 'Realizado',
    es_sales: 'Realizado',
    po: 'Realizado',
  },
  unrealized: {
    en: 'Unrealized',
    es: 'Por realizar',
    es_sales: 'Por realizar',
    po: 'Não realizado',
  },
  totals: {
    en: "Today's Totals",
    es: 'Totales del día',
    es_sales: 'Totales del día',
    po: 'Totais de hoje',
  },
  no_trades_found: {
    en: 'No orders found in this timeframe...',
    es: 'No se encontraron ordenes en este periodo...',
    es_sales: 'No se encontraron ordenes en este periodo...',
    po: 'Nenhuma encomenda encontrada neste período de tempo...',
  },
  reset: {
    en: 'reset',
    es: 'reiniciar',
    es_sales: 'reiniciar',
    po: 'reset',
  },
  reset_24_hours: {
    en: 'reset 24 hours',
    es: 'vista 24 horas',
    es_sales: 'vista 24 horas',
    po: 'reset 24 hours',
  },
  config_widget: {
    en: 'Settings',
    es: 'Ajustes',
    es_sales: 'Ajustes',
    po: 'Definições',
  },
  flat: {
    en: 'flat',
    es: 'nivelar',
    es_sales: 'nivelar',
    po: 'flat',
  },
  flatten: {
    en: 'flatten',
    es: 'nivelar',
    es_sales: 'nivelar',
    po: 'flatten',
  },
  all: {
    en: 'all',
    es: 'todo',
    es_sales: 'todo',
    po: 'all',
  },
  // <------------ New PNL widget
  flat_all: {
    en: 'flat all',
    es: 'nivelar todo',
    es_sales: 'nivelar todo',
    po: 'flat all',
  },
  reset_view: {
    en: 'reset',
    es: 'reiniciar',
    es_sales: 'reiniciar',
    po: 'reset',
  },
  all_strategies: {
    en: 'All Strategies',
    es: 'Estrategias',
    es_sales: 'Estrategias',
    po: 'Estrategias',
  },
  username: {
    en: 'User',
    es: 'Usuario',
    es_sales: 'Usuario',
    po: 'User',
  },
  instrument: {
    en: 'Symbol',
    es: 'Instrumento',
    es_sales: 'Instrumento',
    po: 'Instrumento',
  },
  position: {
    en: 'Position',
    es: 'Posición',
    es_sales: 'Posición',
    po: 'Posição',
  },
  net_price: {
    en: 'Net price',
    es: 'Precio neto',
    es_sales: 'Precio neto',
    po: 'Net price',
  },
  open_price: {
    en: 'Open Price',
    es: 'Precio de Posicion',
    es_sales: 'Precio de Posicion',
    po: 'Open Price',
  },
  avg_buy_price: {
    en: 'Avg buy price',
    es: 'Precio compra',
    es_sales: 'Precio venta',
    po: 'Avg buy price',
  },
  avg_sell_price: {
    en: 'Avg sell price',
    es: 'Precio venta',
    es_sales: 'Precio compra',
    po: 'Avg sell price',
  },
  base_buy: {
    en: 'Base buy',
    es: 'Monto Compra',
    es_sales: 'Monto Venta',
    po: 'Base buy',
  },
  base_sell: {
    en: 'Base sell',
    es: 'Monto Venta',
    es_sales: 'Monto Compra',
    po: 'Base sell',
  },
  term_position: {
    en: 'Term position',
    es: 'Posición de término',
    es_sales: 'Posición de término',
    po: 'Term position',
  },
  term_buy: {
    en: 'Term buy',
    es: 'Monto Compra Término',
    es_sales: 'Monto Venta Término',
    po: 'Term buy',
  },
  term_sell: {
    en: 'Term sell',
    es: 'Monto Venta Término',
    es_sales: 'Monto Compra Término',
    po: 'Term sell',
  },
  filter: {
    en: 'Filter',
    es: 'Filtro',
    es_sales: 'Filtro',
    po: 'Filter',
  },
};

export const pricing_widget = {
  fl_title: flexLayout.pricing,
  btn_restart: {
    en: 'Double click to restart',
    es: 'Haga doble clic para reiniciar',
    es_sales: 'Haga doble clic para reiniciar',
    po: 'Clique duas vezes para reiniciar',
  },
  disabled_pricing: {
    en: 'Disabled Pricing',
    es: 'Precios deshabilitados',
    es_sales: 'Precios deshabilitados',
    po: 'Preços desativados',
  },
  enabled_pricing: {
    en: 'Enable Pricing',
    es: 'Habilitar precios',
    es_sales: 'Habilitar precios',
    po: 'Ativar preços',
  },
  btn_risk: {
    en: 'Risk',
    es: 'Riesgo',
    es_sales: 'Riesgo',
    po: 'ativo',
  },
  state_active: {
    en: 'Active',
    es: 'Activo',
    es_sales: 'Activo',
    po: 'ativo',
  },
  state_inactive: {
    en: 'Inactive',
    es: 'Inactivo',
    es_sales: 'Inactivo',
    po: 'inativo',
  },
};

export const tradingPopout_wiget = {
  error_expiration_date: {
    en: 'The Expiration Date cannot be set during platform’s downtimes, please amend Expiration Date',
    es: 'La fecha de vencimiento no se puede establecer durante los tiempos de inactividad de la plataforma, modifique la fecha de vencimiento',
    es_sales:
      'La fecha de vencimiento no se puede establecer durante los tiempos de inactividad de la plataforma, modifique la fecha de vencimiento',
    po: 'A Data de Expiração não pode ser definida durante tempos de inatividade da plataforma, altere a Data de Expiração',
  },
  missing_data: {
    en: 'Missing data',
    es: 'Falta información',
    es_sales: 'Falta información',
    po: 'Falta informação',
  },
  start_date_error_based_on_now: {
    en: 'Start Time greater than today',
    es: 'Fecha de Inicio mayor a hoy',
    es_sales: 'Fecha de Inicio mayor a hoy',
    po: 'Horário de Início maior que hoje',
  },
  start_date_error_based_on_end: {
    en: 'Expire Time must be greater than Start Time',
    es: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
    es_sales: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
    po: 'O tempo de expiração deve ser maior que o tempo de início',
  },
  start_date_error: {
    en: 'Expire must be greater than Start Time',
    es: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
    es_sales: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
    po: 'A expiração deve ser maior que a Hora de início',
  },
  end_date_error: {
    en: 'Expire Time must be greater than Start Time',
    es: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
    es_sales: 'La Fecha de Expiración debe ser mayor que la hora de inicio',
    po: 'O tempo de expiração deve ser maior que o tempo de início',
  },
  buy: {
    en: 'Buy',
    es: 'Compra',
    es_sales: 'b venta',
    po: 'Comprar',
  },
  sell: {
    en: 'Sell',
    es: 'Venta',
    es_sales: 'b compra',
    po: 'Vender',
  },
  amount: {
    en: 'Amount',
    es: 'Monto',
    es_sales: 'Monto',
    po: 'Quantia',
  },
  amount_to_show: {
    en: 'Amount to show',
    es: 'Importe a mostrar ',
    es_sales: 'Importe a mostrar ',
    po: 'Quantidade a mostrar',
  },
  price: {
    en: 'Rate',
    es: 'precio limite',
    es_sales: 'precio limite',
    po: 'preço limite',
  },
  price_oco: {
    en: 'Limit price',
    es: 'Precio Limite',
    es_sales: 'Precio Limite',
    po: 'Limite de Compra',
  },
  price_stop_limit: {
    en: 'Stop limit rate',
    es: 'precio limite',
    es_sales: 'precio limite',
    po: 'preço limite',
  },
  price2: {
    en: 'Price',
    es: 'Precio',
    es_sales: 'precio',
    po: 'Preço',
  },
  send: {
    en: 'Send',
    es: 'Enviar',
    es_sales: 'Enviar',
    po: 'Enviar',
  },
  gtt_err_dates: {
    en: 'End date must be greater than the start date',
    es: 'La fecha de final debe ser mayor que la fecha de inicio',
    es_sales: 'La fecha de final debe ser mayor que la fecha de inicio',
    po: 'A data de término deve ser posterior à data de início',
  },
  clear_all: {
    en: 'Clear all',
    es: 'Limpiar todo',
    es_sales: 'Limpiar todo',
    po: 'Limpar tudo',
  },
  start_date_err: {
    en: 'The start date cannot be less than this date',
    es: 'La fecha de inicio no puede ser menor a esta fecha',
    es_sales: 'La fecha de inicio no puede ser menor a esta fecha',
    po: 'A data de início não pode ser inferior a esta data',
  },
  start_date_time: {
    en: 'Start date time',
    es: 'Fecha de inicio/hora',
    es_sales: 'Fecha de inicio/hora',
    po: 'Data e hora de início',
  },
  end_date_time: {
    en: 'End date time',
    es: 'Fecha de finalización',
    es_sales: 'Fecha de finalización',
    po: 'Data e hora de término',
  },
  stop_price: {
    en: 'Stop Price',
    es: 'precio stop ',
    es_sales: 'precio stop',
    po: 'paragem de preço',
  },
  stop_price_stop_limit: {
    en: 'stop trigger rate',
    es: 'precio stop ',
    es_sales: 'precio stop',
    po: 'paragem de preço',
  },
  stop_price_oco: {
    en: 'Market Stop Loss',
    es: 'Stop loss - Mercado',
    es_sales: 'Stop loss - Mercado',
    po: 'Stop loss - Mercado',
  },
  start_date: {
    en: 'Start',
    es: 'Inicio',
    es_sales: 'Inicio',
    po: 'Iniciar',
  },
  end_date: {
    en: 'End',
    es: 'Fin',
    es_sales: 'Fin',
    po: 'Fim',
  },
  i_buy: {
    en: 'i buy',
    es: 'yo compro',
    es_sales: 'b vendo',
    po: 'eu compro',
  },
  i_sell: {
    en: 'i sell',
    es: 'yo vendo',
    es_sales: 'b compro',
    po: 'eu vendo',
  },
  market: {
    en: 'market',
    es: 'mercado',
    es_sales: 'mercado',
    po: 'mercado',
  },
  confirm: {
    en: 'confirm',
    es: 'confirmar',
    es_sales: 'confirmar',
    po: 'confirmar',
  },
  cancel: {
    en: 'cancel',
    es: 'cancelar',
    es_sales: 'cancelar',
    po: 'cancelar',
  },
  order_type: {
    LIMIT: {
      en: 'Limit',
      es: 'Limite',
      es_sales: 'Limite',
      po: 'Limite',
    },
    STOP_LIMIT: {
      en: 'Stop Limit',
      es: 'Stop Loss - Limite',
      es_sales: 'Stop Loss - Limite',
      po: 'Stop Loss - Limite',
    },
    STOP_MARKET: {
      en: 'Stop Market',
      es: 'Stop Loss - Mercado',
      es_sales: 'Stop Loss - Mercado',
      po: 'Stop Loss - Mercado',
    },
    ICEBERG: {
      en: 'iceberg',
      es: 'iceberg',
      es_sales: 'iceberg',
      po: 'icebergue',
    },
    MAN_OFFSET: {
      en: 'man offset',
      es: 'man offset',
      es_sales: 'man offset',
      po: 'man offset',
    },
    FIXING: {
      en: 'Fixing',
      es: 'Fijar',
      es_sales: 'Fijar',
      po: 'Fixar',
    },
  },
};

export const errorBoundry = {
  widget_crashed_error: {
    en: 'Please press F5 to refresh widget.',
    es: 'Por favor presione F5 para actualizar el widget.',
    es_sales: 'Por favor presione F5 para actualizar el widget.',
    po: 'Por favor, pressione F5 para atualizar o widget.',
  },
};

export const rfq_widget = {
  fl_title: flexLayout.rfq,
  settlement: {
    en: 'Settlement',
    es: 'Settlement',
    es_sales: 'Settlement',
    po: 'Liquidação',
  },
  fixing: {
    en: 'Fixing',
    es: 'Fixing',
    es_sales: 'Fixing',
    po: 'Fixing',
  },
  swap: {
    en: 'Swap',
    es: 'Swap',
    es_sales: 'Swap',
    po: 'Swap',
  },
  near_leg: {
    en: 'Near Leg',
    es: 'Pata Corta',
    es_sales: 'Pata Corta',
    po: 'Primeiro Vencimento',
  },
  far_leg: {
    en: 'Far Leg',
    es: 'Pata Larga',
    es_sales: 'Pata Larga',
    po: 'Segundo Vencimento',
  },
  deal_done: {
    en: 'Deal Done',
    es: 'Operación Ejecutada',
    es_sales: 'Operación Ejecutada',
    po: 'Deal Done',
  },
  deal_canceled: {
    en: 'Deal Canceled',
    es: 'Operación Cancelada',
    es_sales: 'Operación Cancelada',
    po: 'Deal Canceled',
  },
  deal_rejected: {
    en: 'Deal Rejected',
    es: 'Operación Rechazada',
    es_sales: 'Operación Rechazada',
    po: 'Deal Rejected',
  },
  nothing_done: {
    en: 'Nothing Done',
    es: 'Nada Hecho',
    es_sales: 'Nada Hecho',
    po: 'Nada Feito',
  },
  deal_expired: {
    en: 'Deal Expired',
    es: 'Operación Expirada',
    es_sales: 'Operación Expirada',
    po: 'Deal Expired',
  },
  all_in: {
    en: 'ALL-IN',
    es: 'ALL-IN',
    es_sales: 'ALL-IN',
    po: 'ALL-IN',
  },
  fwd_points: {
    en: 'Fwd points',
    es: 'Fwd points',
    es_sales: 'Fwd points',
    po: 'Fwd points',
  },
  complete_opts: {
    en: 'Complete the options to enable price request',
    es: 'Complete las opciones para habilitar la solicitud de precio',
    es_sales: 'Complete las opciones para habilitar la solicitud de precio',
    po: 'Preencha as opções para habilitar a solicitação de preço',
  },
  waiting_reply: {
    en: 'Waiting for reply',
    es: 'Esperando respuesta',
    es_sales: 'Esperando respuesta',
    po: 'Aguardando Resposta',
  },
  two_way: {
    en: '2 way',
    es: 'Ambos',
    es_sales: 'Ambos',
    po: '2 way',
  },
  cancel_request: {
    en: 'Cancel Request',
    es: 'Cancelar Solicitud',
    es_sales: 'Cancelar Solicitud',
    po: 'Cancelar Solicitação',
  },
  accept: {
    en: 'Accept',
    es: 'Aceptar',
    es_sales: 'Aceptar',
    po: 'Accept',
  },
  hit: {
    en: 'Hit',
    es: 'Aceptar',
    es_sales: 'Aceptar',
    po: 'Aceptar',
  },
  take: {
    en: 'Take',
    es: 'Aceptar',
    es_sales: 'Aceptar',
    po: 'Aceptar',
  },
  reject: {
    en: 'Reject',
    es: 'Rechazar',
    es_sales: 'Rechazar',
    po: 'Rejeitar',
  },
  back: {
    en: 'Back',
    es: 'Volver',
    es_sales: 'Volver',
    po: 'Voltar',
  },
  fixing_date_near: {
    en: 'Fixing Date Near',
    es: 'Fecha Fixing Corta',
    es_sales: 'Fecha Fixing Corta',
    po: 'Fixing Date Near',
  },
  fixing_date_far: {
    en: 'Fixing Date Far',
    es: 'Fecha Fixing Larga',
    es_sales: 'Fecha Fixing Larga',
    po: 'Fixing Date Far',
  },
  near_leg_settlement_date: {
    en: 'Near Leg Settlement Date',
    es: 'Pata Corta Fecha Valor',
    es_sales: 'Pata Corta Fecha Valor',
    po: 'Data de Liquidação do Primeiro Vencimento',
  },
  far_leg_settlement_date: {
    en: 'Far Leg Settlement Date',
    es: 'Pata Larga Fecha Valor',
    es_sales: 'Pata Larga Fecha Valor',
    po: 'Data de Liquidação do Segundo Vencimento',
  },
  buy: {
    en: 'Buy ',
    es: 'Compra ',
    es_sales: 'B Venta',
    po: 'Comprar ',
  },
  sell: {
    en: 'Sell ',
    es: 'Venta ',
    es_sales: 'B Compra ',
    po: 'Vender ',
  },
};

export const rfq_blotter_widget = {
  fl_title: flexLayout.rfq_blotter,
  status: {
    en: 'Status',
    es: 'Estatus',
    es_sales: 'Estatus',
    po: 'Estado',
  },
  type: {
    en: 'Type',
    es: 'Tipo',
    es_sales: 'Tipo',
    po: 'Tipo',
  },
  quoting: {
    en: 'Quoting',
    es: 'Cuota',
    es_sales: 'Cuota',
    po: 'Quoting',
  },
  client: {
    en: 'Client',
    es: 'Cliente',
    es_sales: 'Cliente',
    po: 'Cliente',
  },
  clientUser: {
    en: 'Client user',
    es: 'Usuario cliente',
    es_sales: 'Usuario cliente',
    po: 'Usuario cliente',
  },
  ccyPair: {
    en: 'CCY Pair',
    es: 'Par de Divisas',
    es_sales: 'Par de Divisas',
    po: 'Par de Divisas',
  },
  deal_superseded: {
    en: 'Deal Superseded by',
    es: 'Cotización intervenida por',
    es_sales: 'Cotización intervenida por',
    po: 'Cotización intervenida por',
  },
  price_near: {
    en: 'Price Near',
    es: 'Price Near',
    es_sales: 'Price Near',
    po: 'Price Near',
  },
  starting_rate: {
    en: 'Starting Rate',
    es: 'Starting Rate',
    es_sales: 'Starting Rate',
    po: 'Starting Rate',
  },
  near_leg_rate: {
    en: 'Near Leg Rate',
    es: 'Precio Pata Corta',
    es_sales: 'Precio Pata Corta',
    po: 'Precio Pata Corta',
  },
  far_leg_rate: {
    en: 'Far Leg Rate',
    es: 'Precio Pata Larga',
    es_sales: 'Precio Pata Larga',
    po: 'Preço do Segundo Vencimento',
  },
  price_far: {
    en: 'Price Far',
    es: 'Price Far',
    es_sales: 'Price Far',
    po: 'Price Far',
  },
  seconds_answer: {
    en: 'Seconds to answer',
    es: 'Segundos para responder',
    es_sales: 'Segundos para responder',
    po: 'Tempo de Resposta (seg.)',
  },
  confirm_deal: {
    en: 'Confirm Deal',
    es: 'Confirmar Operación',
    es_sales: 'Confirmar Operación',
    po: 'Confirmar Operación',
  },
  confirm: {
    en: 'Confirm',
    es: 'Confirmar',
    es_sales: 'Confirmar',
    po: 'Confirmar',
  },
  deal: {
    en: 'Deal',
    es: 'Operación',
    es_sales: 'Operación',
    po: 'Operación',
  },
  modify: {
    en: 'Modify',
    es: 'Modificar',
    es_sales: 'Modificar',
    po: 'Modificar',
  },
  quotes: {
    en: 'Quotes',
    es: 'Precios',
    es_sales: 'Precios',
    po: 'Preço',
  },
  request: {
    en: 'Request',
    es: 'Solicitar',
    es_sales: 'Solicitar',
    po: 'Request',
  },
  sell_price: {
    en: 'Sell Price',
    es: 'Precio Venta',
    es_sales: 'Precio Venta',
    po: 'Precio Venta',
  },
  buy_price: {
    en: 'Buy Price',
    es: 'Precio Compra',
    es_sales: 'Precio Compra',
    po: 'Precio Compra',
  },
  incoming_quote: {
    en: 'Incoming Request For Quote',
    es: 'Nueva Solicitud de Cotización',
    es_sales: 'Nueva Solicitud de Cotización',
    po: 'Nueva Solicitud de Cotización',
  },
  incoming_quote_response: {
    en: 'Quote response received',
    es: 'Respuesta de cotización recibida',
    es_sales: 'Respuesta de cotización recibida',
    po: 'Respuesta de cotización recibida',
  },
  creation_time: {
    en: 'Creation Time',
    es: 'Fecha de Captura',
    es_sales: 'Fecha de Captura',
    po: 'Tempo de captura',
  },
  timestamp: {
    en: 'Execution Time',
    es: 'Fecha de Ejecución',
    es_sales: 'Fecha de Ejecución',
    po: 'Data de Comercialização',
  },
  organization_name: {
    en: 'Organization',
    es: 'Organización',
    es_sales: 'Organización',
    po: 'Organização',
  },
  export: {
    en: 'Export',
    es: 'Exportar',
    es_sales: 'Exportar',
    po: 'Exportar',
  },
  all_in_out_of_range: {
    en: 'All-in Rate out of (%1) range',
    es: 'Precio All-in está fuera de rango (1%)',
    es_sales: 'Precio All-in está fuera de rango (1%)',
    po: 'Precio All-in está fuera de rango (1%)',
  },
  all_in_price_inversion: {
    en: 'All-in Buy price cannot be higher than All-in Sell price',
    es: 'El precio All-in de Compra no puede ser mayor al precio All-in de Venta',
    es_sales:
      'El precio All-in de Compra no puede ser mayor al precio All-in de Venta',
    po: 'El precio All-in de Compra no puede ser mayor al precio All-in de Venta',
  },
  negative_swap_points: {
    en: 'Swap Points Rate cannot be negative',
    es: 'Los puntos SWAP cotizados no pueden ser negativos',
    es_sales: 'Los puntos SWAP cotizados no pueden ser negativos',
    po: 'Los puntos SWAP cotizados no pueden ser negativos',
  },
  settle_date: {
    en: 'Settlement Date',
    es: 'Fecha Valor',
    es_sales: 'Fecha Valor',
  },
  product: {
    en: 'Product',
    es: 'Producto',
    es_sales: 'Producto',
    po: 'Produto',
  },
  tenor: {
    en: 'Tenor',
    es: 'Tenor',
    es_sales: 'Tenor',
    po: 'Tenor',
  },
  amount: {
    en: 'Amount',
    es: 'Monto',
    es_sales: 'Monto',
    po: 'Quantidade',
  },
  send: {
    en: 'Send',
    es: 'Enviar',
    es_sales: 'Enviar',
    po: 'Send',
  },
};

export const charts_widget = {
  fl_title: flexLayout.chart,
};

export const economic_calendar_widget = {
  fl_title: flexLayout.economic_calendar,
};

export const rss_feed_widget = {
  fl_title: flexLayout.rss_feed,
};

export const heatmap_widget = {
  fl_title: flexLayout.heatmap,
};
