import { Order } from '~types/common';

export const skipRateEngineOrder = (
  order: Order,
  skipRateEngineClientOrders: boolean
) => {
  const isReOrder = !!(order.re_value1 || order.re_value2);
  const isNotCover = !order.cover_organization_name;
  return skipRateEngineClientOrders && isReOrder && isNotCover;
};

export const filterRateEngineOrders = (
  orders: Order[],
  skipRateEngineClientOrders: boolean
) => {
  const coverIds: string[] = [];

  if (!skipRateEngineClientOrders) {
    return orders;
  }

  // filter RE trade
  const filteredOrders = orders.filter((o) => {
    const needSkip = skipRateEngineOrder(o, skipRateEngineClientOrders);

    if (needSkip) {
      if (o.re_value1 && !coverIds.includes(o.re_value1)) {
        coverIds.push(o.re_value1);
      }

      if (o.re_value2 && !coverIds.includes(o.re_value2)) {
        coverIds.push(o.re_value2);
      }
    }

    return !needSkip;
  });

  // trade cover
  if (coverIds.length) {
    return filteredOrders.filter(
      (o) =>
        !o.cover_client_order_id ||
        (o.cover_client_order_id && !coverIds.includes(o.cover_client_order_id))
    );
  }

  return filteredOrders;
};
