/**
 * Calculates number of digits after decimal point for numbers and numbers expressed as strings.
 * Note: numbers expressed as strings may contain trailing zeros to be included.
 *
 * @param {number|string} num
 * @returns
 */
const decimalPlaces = (num: number | string): number => {
  const match = `${num}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }

  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
};

export default decimalPlaces;
