import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const metalsFixingMarketsStatusesSelector = createSelector(
  [(state) => state.metalsFixingMarketStatuses],
  (metalsFixingMarketStatuses) =>
    isEmpty(metalsFixingMarketStatuses) ? {} : metalsFixingMarketStatuses
);

export const metalsFixingMarketsStatusesSelectorById = createSelector(
  [
    (state) => state.metalsFixingMarketStatuses,
    (_, instrumentId) => instrumentId,
  ],
  (metalsFixingMarketStatuses, instId) => metalsFixingMarketStatuses?.[instId]
);
