import frontLogger from '~utils/log';
import {
  ADD_SUB,
  REMOVE_SUB,
  UPDATE_SUB_ALGO,
  ADD_MULTIPLE_SUBS,
  USER_DETAILS,
} from '../actions/action_types';

const logWs = frontLogger('websocket:reducer:subscription');
const isSubNotFound = (state, id, algo) =>
  !state[id] || !state[id][algo] || state[id][algo].length === 0;

const defineStopSubFn =
  (isEnableWebsocketRouting = false) =>
  (id, algo) => {
    if (window?.socket?.readyState !== 1) return;

    if (isEnableWebsocketRouting) {
      logWs.trace(`msg -> upstream STOP SUB id: ${id} algo: ${algo}`);
      window.socket.send(
        JSON.stringify({
          body: {
            msg_type: 'stop-instrument-subscription',
            data: { id, algo },
          },
        })
      );
    } else {
      logWs.trace(
        `msg -> upstream (DISABLED) STOP SUB id: ${id} algo: ${algo}`
      );
    }
  };

let stopSubscription = defineStopSubFn();

export default function subscriptionsReducer(state = {}, action) {
  let id = '';
  let algo = '';
  let widget = '';

  switch (action.type) {
    case REMOVE_SUB: {
      id = action.payload.id;
      algo = action.payload.algo;
      widget = action.payload.widget;
      if (!state[id] || !state[id][algo]) return state;
      let updatedList = removeWidgetFromList(state[id][algo], widget);
      if (updatedList.length === 0) {
        stopSubscription(id, algo);
        updatedList = undefined;
      }

      return {
        ...state,
        [id]: {
          ...state[id],
          [algo]: updatedList,
        },
      };
    }
    case ADD_SUB:
      id = action.payload.id;
      algo = action.payload.algo;
      widget = action.payload.widget;

      if (isSubNotFound(state, id, algo)) startSubscription(id, algo);
      if (!state[id]) return { ...state, [id]: { [algo]: [widget] } };
      if (!state[id][algo])
        return { ...state, [id]: { ...state[id], [algo]: [widget] } };
      if (state[id][algo].includes(widget)) return state;
      return {
        ...state,
        [id]: {
          ...state[id],
          [algo]: addWidgetToList(state[id][algo], widget),
        },
      };
    case ADD_MULTIPLE_SUBS: {
      let newState = { ...state };
      for (let i = 0; i < action.payload.length; i += 1) {
        id = action.payload[i].id;
        algo = action.payload[i].algo;
        widget = action.payload[i].id;

        if (
          !newState[id] ||
          !newState[id][algo] ||
          newState[id][algo].length === 0
        ) {
          startSubscription(id, algo);
        }
        if (!newState[id]) {
          newState = { ...newState, [id]: { [algo]: [widget] } };
        }
        if (!newState[id][algo]) {
          newState = {
            ...newState,
            [id]: { ...newState[id], [algo]: [widget] },
          };
        }

        if (!newState[id][algo].includes(widget)) {
          newState = {
            ...newState,
            [id]: {
              ...newState[id],
              [algo]: addWidgetToList(newState[id][algo], widget),
            },
          };
        }
      }

      return newState;
    }
    case UPDATE_SUB_ALGO:
      return state;
    case USER_DETAILS: {
      stopSubscription = defineStopSubFn(
        action.payload.isEnableWebsocketRouting
      );
      return state;
    }
    default:
      return state;
  }
}

const addWidgetToList = (list, widget) => [...list, widget];

const removeWidgetFromList = (list, widget) => list.filter((w) => w !== widget);

const startSubscription = (id, algo) => {
  if (window?.socket?.readyState !== 1) return;

  logWs.trace(`msg -> upstream START SUB id: ${id} algo: ${algo}`);

  window.socket.send(
    JSON.stringify({
      body: {
        msg_type: 'start-instrument-subscription',
        data: { id, algo },
      },
    })
  );
};

/**
 * given subscription (non-rfs/rfq) state, return instrument + algo tuples
 * for all in use by one or more widget(s), excluding empty widget identities
 *
 * @param {Object<number, Object<string, array<string>>>} insWidgetSubs
 * @returns {Array<Array<string>>}
 *
 */
export const instrumentAlgoWidgetSubs = (insWidgetSubs) => {
  const insAlgosWidgetSubs = Object.entries(insWidgetSubs).filter(
    (i) => i[1] != null && Object.keys(i[1]).length > 0
  );
  return insAlgosWidgetSubs.reduce((acc, cur) => {
    const algosWidgetSubs = Object.entries(cur[1]);
    if (
      Array.isArray(algosWidgetSubs[0][1]) &&
      algosWidgetSubs[0][1].filter(
        (item) =>
          item != null && typeof item === 'string' && item.trim().length > 0
      ).length > 0
    ) {
      return [...acc, [cur[0], algosWidgetSubs[0][0]]];
    }
    return acc;
  }, []);
};
