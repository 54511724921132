import logger from '~utils/logger';
import { FOLLOW_CLIENTS, FOLLOW_THIS_CLIENT } from '~actions/action_types';

export default function followClientsReducer(
  state = {
    organizations: [],
    users: [],
    super_orders_viewer: false,
    super_shadow_viewer: false,
    orders: false,
    shadow_orders: false,
    show_own_cover: false,
    show_viewer_cover: false,
    viewerOrganizationIds: [],
    viewerUserIds: [],
  },
  action
) {
  switch (action.type) {
    case FOLLOW_CLIENTS:
      return { ...state, ...action.payload };
    case FOLLOW_THIS_CLIENT:
      if (window?.socket?.readyState === 1) {
        window.socket.send(
          JSON.stringify({
            body: {
              msg_type: 'check-client-spread',
              data: action.payload,
            },
          })
        );
      } else {
        logger.logNotReadyMsg('check-client-spread');
      }
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
}
