import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';

import {
  INCOMING_ORDER,
  CHANGE_FOLLOWER,
  SET_INITIAL_ORDERS,
} from '../actions/action_types';

import { filterRateEngineOrders } from '../utils/rateEngineClientOrders';

const STATUS_PRIORITY = {
  PENDING_NEW: 0,
  NEW: 1,
  PARTIALLY_FILLED: 2,
  PENDING_CANCEL: 3,
  FILLED: 4,
  REJECTED: 5,
  EXPIRED: 6,
  CANCELED: 7,
};

/**
 * Returns the right order on an update
 * @param {*} newOrder
 * @param {*} oldOrder
 * @returns order
 */
const validateOrderUpdate = (newOrder, oldOrder) => {
  if (!oldOrder) return newOrder;

  // milliseconds does affect
  const oldOrderTimestamp = oldOrder.timestamp;
  const newOrderTimestamp = newOrder.timestamp;

  if (oldOrderTimestamp < newOrderTimestamp) {
    return newOrder;
  }

  if (oldOrderTimestamp === newOrderTimestamp) {
    // microseconds does not affect the datetime. Be carefull
    const oStatus = STATUS_PRIORITY[oldOrder.status];
    const nStatus = STATUS_PRIORITY[newOrder.status];

    if (oStatus < nStatus) {
      return newOrder;
    }

    if (oStatus === nStatus) {
      if (
        Number(newOrder.qty_original) !== Number(oldOrder.qty_original) ||
        Number(newOrder.price) !== Number(oldOrder.price) ||
        (Number(newOrder.stop_price) !== Number(oldOrder.stop_price) &&
          !Number.isNaN(Number(newOrder.stop_price)))
      ) {
        return newOrder;
      }

      if (Number(oldOrder.qty_open) > Number(newOrder.qty_open)) {
        return newOrder;
      }
    }
  }

  return oldOrder;
};

export default function ordersReducer(
  state = { followClient: 0, followOrders: {}, orders: {} },
  action
) {
  switch (action.type) {
    case SET_INITIAL_ORDERS: // currently only used when small box popout
      return cloneDeep(action.payload);
    case INCOMING_ORDER: {
      const { followClient } = state;
      const newOrders = {};
      const newFollows = {};

      const { orders: comingOrders, skipRateEngineClientOrders = false } =
        action.payload;

      let filterComingOrders = [...comingOrders];
      if (skipRateEngineClientOrders) {
        filterComingOrders = filterRateEngineOrders(
          filterComingOrders,
          skipRateEngineClientOrders
        );
      }

      for (let i = 0; i < filterComingOrders.length; i += 1) {
        const order = filterComingOrders[i];
        newOrders[order.client_order_id] = validateOrderUpdate(
          order,
          newOrders[order.client_order_id] ||
            state.orders[order.client_order_id]
        );
        if (followClient && order.organization_id === followClient) {
          newFollows[order.client_order_id] = validateOrderUpdate(
            order,
            newFollows[order.client_order_id] ||
              state.followOrders[order.client_order_id]
          );
        }
      }

      const newState = {
        followClient,
        followOrders: {
          ...state.followOrders,
          ...newFollows,
        },
        orders: {
          ...state.orders,
          ...newOrders,
        },
      };

      return newState;
    }

    case CHANGE_FOLLOWER: {
      const newFollowClient = Number(action.payload);
      const newArrayFO = filter(
        state.orders,
        (o) => o.organization_id === newFollowClient
      );

      const newFollowOrdersObject = {};

      for (let i = 0; i < newArrayFO.length; i += 1) {
        newFollowOrdersObject[newArrayFO[i].client_order_id] = newArrayFO[i];
      }

      return {
        ...state,
        followClient: newFollowClient,
        followOrders: newFollowOrdersObject,
      };
    }

    default:
      return state;
  }
}
