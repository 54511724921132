// TODO: HIGH ---------
// Once we want to release the MFC we would need to uncomment the MFC comments so the subscriptions can be done propertly

import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

import { formatDateWithoutSlashes } from '~utils/formatDate';

import {
  START_RFQ_RFS_SUB,
  START_RFQ_RFS_SUB_ALL,
  REMOVE_RFQ_RFS_SUB,
  REMOVE_RFQ_RFS_SUB_ALL,
  UPDATE_ASSET_RFQ,
} from '~actions/action_types';

export const dtoActionPayload = ({
  streamType,
  instrumentId,
  instrumentPair,
  tenor,
  tenorFar,
  date,
  dateFar,
  datePublish,
  clientRequestId,
  instrumentIdUnsub,
  dateUnsub,
  dateFarUnsub,
  tenorUnsub,
  tenorFarUnsub,
  widgetRef: widget,
  amount,
  amountUnsub,
  fwdCurveVersion,
  fwdCurveVersionUnsub,
  side,
  isRfq = false,
  parentInternalRequestId = null,
}) => ({
  streamType,
  instrumentId,
  instrumentPair,
  tenor,
  tenorFar,
  date,
  dateFar,
  datePublish,
  clientRequestId,
  instrumentIdUnsub,
  dateUnsub,
  dateFarUnsub,
  tenorUnsub,
  tenorFarUnsub,
  widget,
  amount,
  amountUnsub,
  fwdCurveVersion,
  fwdCurveVersionUnsub,
  side,
  isRfq,
  parentInternalRequestId,
});

export const dtoActionStopSubPayload = ({
  streamType,
  instrumentId,
  instrumentPair,
  tenor,
  tenorFar,
  date,
  dateFar,
  clientRequestId,
  widgetRef: widget,
  amount,
  fwdCurveVersion,
  isRfq = false,
}) => ({
  streamType,
  instrumentId,
  instrumentPair,
  tenor,
  tenorFar,
  date,
  dateFar,
  clientRequestId,
  widget,
  amount,
  fwdCurveVersion,
  isRfq,
});

export function dtoActionUpdateSubAssetPayload({
  instrument_id: instrumentId,
  rfq,
}) {
  const rfqSubs =
    Array.isArray(rfq) &&
    rfq
      .map((sub) => ({
        clientRequestId: sub.clientRequestId,
        amount: sub.amount,
        dateTenor: !sub.tenor ? formatDateWithoutSlashes(sub.date) : sub.tenor,
        dateTenorFar: !sub.tenorFar
          ? formatDateWithoutSlashes(sub.dateFar)
          : sub.tenorFar,
        fwdCurveVersion: sub.fwdCurveVersion,
        isRfq: sub.isRfq || false,
      }))
      .filter(
        (items) =>
          items.clientRequestId != null &&
          items.clientRequestId !== '' &&
          items.dateTenor !== ''
      );

  return {
    instrumentId,
    rfqSubs,
  };
}

export const prepDateTenor = (date, tenor) =>
  !tenor ? formatDateWithoutSlashes(date) : tenor;

export function startRfqRfsSub(state, payload) {
  const {
    streamType,
    instrumentId,
    instrumentPair,
    tenor,
    tenorFar,
    date,
    dateFar,
    datePublish,
    clientRequestId,
    instrumentIdUnsub,
    dateUnsub,
    dateFarUnsub,
    tenorUnsub,
    tenorFarUnsub,
    widget,
    amount,
    amountUnsub,
    fwdCurveVersion,
    fwdCurveVersionUnsub,
    side,
    isRfq = false,
    parentInternalRequestId,
  } = dtoActionPayload(payload);

  const dateTenor = prepDateTenor(date, tenor);
  const dateTenorFar = prepDateTenor(dateFar, tenorFar);
  const dateTenorUnsub = prepDateTenor(dateUnsub, tenorUnsub);
  const dateTenorFarUnsub = prepDateTenor(dateFarUnsub, tenorFarUnsub);

  const subscriptionString = `${dateTenor}${dateTenorFar}${amount}${fwdCurveVersion}${isRfq}`;
  const unsubscriptionString = `${dateTenorUnsub}${dateTenorFarUnsub}${amountUnsub}${fwdCurveVersionUnsub}${isRfq}`;

  // if no state, return new
  if (!state[instrumentId] && !state[instrumentIdUnsub]) {
    startRfqRfsSubscription({
      streamType,
      instrumentId,
      instrumentPair,
      tenor,
      tenorFar,
      date,
      dateFar,
      datePublish,
      clientRequestId,
      widget,
      amount,
      fwdCurveVersion,
      side,
      isRfq,
      parentInternalRequestId,
    });
    return {
      ...state,
      [instrumentId]: {
        [`${subscriptionString}`]: {
          clientRequestId: null,
          subscribers: [widget],
        },
      },
    };
  }
  // if no existing pair (e.g. user switched pairs), but is exisiting pair to unsubscribe
  if (!state[instrumentId] && state[instrumentIdUnsub]) {
    // Copy existing state
    const instrumentSubsCopy = {
      ...state[instrumentIdUnsub],
    };

    // delete instrument/dateTenorUnsub/dateTenorFarUnsub combo
    delete instrumentSubsCopy[`${unsubscriptionString}`];

    const newState = {
      ...state,
      [instrumentIdUnsub]: instrumentSubsCopy,
    };

    if (Object.keys(instrumentSubsCopy).length > 0) {
      // return new state after deletion
      return {
        ...newState,
        [instrumentId]: {
          [subscriptionString]: {
            clientRequestId: null,
            subscribers: [widget],
          },
        },
      };
    }

    const stateCopy = {
      ...state,
    };
    delete stateCopy[instrumentIdUnsub];

    startRfqRfsSubscription({
      streamType,
      instrumentId,
      instrumentPair,
      tenor,
      tenorFar,
      date,
      dateFar,
      datePublish,
      clientRequestId,
      widget,
      amount,
      fwdCurveVersion,
      side,
      isRfq,
      parentInternalRequestId,
    });

    return {
      ...stateCopy,
      [instrumentId]: {
        [`${subscriptionString}`]: {
          clientRequestId: null,
          subscribers: [widget],
        },
      },
    };
  }
  // if has pair id to subscribe, but not have pair id with date/tenor combo and has pair id to unsubscribe
  if (
    state[instrumentId] &&
    !state[instrumentId][`${subscriptionString}`] &&
    state[instrumentIdUnsub]
  ) {
    const subscribersUnsubCount =
      (state[instrumentIdUnsub] &&
        state[instrumentIdUnsub][`${unsubscriptionString}`] &&
        state[instrumentIdUnsub][`${unsubscriptionString}`].subscribers) ||
      [];

    // Copy existing state
    let instrumentSubsCopy = {
      ...state[instrumentIdUnsub],
    };

    if (subscribersUnsubCount.length <= 1) {
      // delete instrument/dateTenorUnsub/dateTenorFarUnsub/fwdCurveVersion combo
      delete instrumentSubsCopy[`${unsubscriptionString}`];
    } else {
      // other subscribers: remove subscriber from list
      const newSubscribersList = subscribersUnsubCount.filter(
        (widgetSubscriber) => widgetSubscriber !== widget
      );
      instrumentSubsCopy = {
        ...instrumentSubsCopy,
        [`${unsubscriptionString}`]: {
          ...instrumentSubsCopy[`${unsubscriptionString}`],
          subscribers: newSubscribersList,
        },
      };
    }
    const newState = {
      ...state,
      [instrumentIdUnsub]: instrumentSubsCopy,
    };

    startRfqRfsSubscription({
      streamType,
      instrumentId,
      instrumentPair,
      tenor,
      tenorFar,
      date,
      dateFar,
      datePublish,
      clientRequestId:
        subscribersUnsubCount.length <= 1 ? clientRequestId : null,
      widget,
      amount,
      fwdCurveVersion,
      side,
      isRfq,
      parentInternalRequestId,
    });

    return {
      ...newState,
      [instrumentId]: {
        ...newState[instrumentId],
        [`${subscriptionString}`]: {
          clientRequestId: null,
          subscribers: [widget],
        },
      },
    };
  }
  // if has pair id to subscribe and has pair id with date/tenor combo and has pair id to unsubscribe and pair id to subscribe is NOT equal to pair id to unsubscribe
  if (
    state[instrumentId] &&
    state[instrumentId][`${subscriptionString}`] &&
    state[instrumentIdUnsub] &&
    instrumentId !== instrumentIdUnsub
  ) {
    // Copy existing state
    const instrumentSubsCopy = {
      ...state[instrumentIdUnsub],
    };

    // delete instrument/dateTenorUnsub/dateTenorFarUnsub combo
    delete instrumentSubsCopy[`${unsubscriptionString}`];

    const newState = {
      ...state,
      [instrumentIdUnsub]: instrumentSubsCopy,
    };

    startRfqRfsSubscription({
      streamType,
      instrumentId,
      instrumentPair,
      tenor,
      tenorFar,
      date,
      dateFar,
      datePublish,
      clientRequestId,
      widget,
      amount,
      fwdCurveVersion,
      side,
      isRfq,
      parentInternalRequestId,
    });

    return {
      ...newState,
      [instrumentId]: {
        ...newState[instrumentId],
        [`${subscriptionString}`]: {
          ...newState[instrumentId][`${subscriptionString}`],
        },
      },
    };
  }

  // if has pair id to subscribe and has pair id with date/tenor combo and has pair id to unsubscribe and pair id to subscribe is equal to pair id to unsubscribe
  if (
    state[instrumentId] &&
    state[instrumentId][`${subscriptionString}`] &&
    state[instrumentIdUnsub] &&
    instrumentId === instrumentIdUnsub
  ) {
    const subscribersList =
      state[instrumentId][`${subscriptionString}`].subscribers || [];

    // TODO: check if existing subscriber
    const isExistingSubscriber = subscribersList.includes(widget);

    if (isExistingSubscriber) {
      const instrumentSubsCopy = {
        ...state[instrumentIdUnsub],
      };

      const newState = {
        ...state,
        [instrumentIdUnsub]: instrumentSubsCopy,
      };

      startRfqRfsSubscription({
        streamType,
        instrumentId,
        instrumentPair,
        tenor,
        tenorFar,
        date,
        dateFar,
        datePublish,
        clientRequestId,
        widget,
        amount,
        fwdCurveVersion,
        side,
        isRfq,
        parentInternalRequestId,
      });

      return {
        ...newState,
        [instrumentId]: {
          ...newState[instrumentId],
          [`${subscriptionString}`]: {
            ...newState[instrumentId][`${subscriptionString}`],
            clientRequestId: null,
          },
        },
      };
    }

    // is NOT existing subscriber
    // Copy existing state
    const instrumentSubsCopy = {
      ...state[instrumentIdUnsub],
    };

    // delete instrument/dateTenorUnsub/dateTenorFarUnsub combo
    delete instrumentSubsCopy[`${unsubscriptionString}`];

    const newState = {
      ...state,
      [instrumentIdUnsub]: instrumentSubsCopy,
    };

    return {
      ...newState,
      [instrumentId]: {
        ...newState[instrumentId],
        [`${subscriptionString}`]: {
          ...newState[instrumentId][`${subscriptionString}`],
          subscribers: [...subscribersList, widget],
        },
      },
    };
  }

  return state;
}

export function startRfqRfsSubAll(state, payload) {
  if (!Array.isArray(payload) || payload.length === 0) return state;

  const boxSubscriptionsState = payload.filter(
    (boxSub) => boxSub.instrumentId !== 0
  ); // do not use default "UNSELECTED" state
  if (boxSubscriptionsState.length === 0) return state;

  const uniqueSubHash = {};
  boxSubscriptionsState.forEach(
    ({
      rfsType,
      clientRequestId,
      instrumentId,
      instrumentPair,
      date,
      dateFar,
      tenor,
      tenorFar,
      amount,
      fwdCurveVersion,
    }) => {
      const subscriptionString = `${tenor}${tenorFar}${amount}${fwdCurveVersion}false`;

      if (
        !uniqueSubHash[
          `${rfsType}${instrumentId}${formatDateWithoutSlashes(
            date
          )}${formatDateWithoutSlashes(dateFar)}${subscriptionString}`
        ]
      ) {
        uniqueSubHash[
          `${rfsType}${instrumentId}${formatDateWithoutSlashes(
            date
          )}${formatDateWithoutSlashes(dateFar)}${subscriptionString}`
        ] = {
          rfsType,
          clientRequestId,
          instrumentId,
          instrumentPair,
          date,
          dateFar,
          tenor,
          tenorFar,
          amount,
          fwdCurveVersion,
        };
      }
    }
  );

  // send realtime message to server
  startRfqRfsSubscriptionAll(Object.values(uniqueSubHash));
  return boxSubscriptionsState.reduce((prev, curr) => {
    const {
      instrumentId,
      tenor,
      tenorFar,
      date,
      dateFar,
      widget,
      amount,
      fwdCurveVersion,
      isRfq = false,
    } = dtoActionPayload(curr);
    const dateTenor = prepDateTenor(date, tenor);
    const dateTenorFar = prepDateTenor(dateFar, tenorFar);

    const subscriptionString = `${dateTenor}${dateTenorFar}${amount}${fwdCurveVersion}${isRfq}`;

    // if no state, return new
    if (!prev[instrumentId])
      return {
        ...prev,
        [instrumentId]: {
          [`${subscriptionString}`]: {
            clientRequestId: null,
            subscribers: [widget],
          },
        },
      };
    // if has pair id, but no associated date/tenor pair
    if (prev[instrumentId] && !prev[instrumentId][`${subscriptionString}`])
      return {
        ...prev,
        [instrumentId]: {
          ...prev[instrumentId],
          [`${subscriptionString}`]: {
            clientRequestId: null,
            subscribers: [widget],
          },
        },
      };
    // if has pair id with associated date/tenor pair
    if (prev[instrumentId] && prev[instrumentId][`${subscriptionString}`])
      return {
        ...prev,
        [instrumentId]: {
          ...prev[instrumentId],
          [`${subscriptionString}`]: {
            ...prev[instrumentId][`${subscriptionString}`],
            subscribers: [
              ...prev[instrumentId][`${subscriptionString}`].subscribers,
              widget,
            ],
          },
        },
      };
    // return existing pair, with existing date or tenor
    return state;
  }, state);
}

export function removeRfqRfsSub(state, payload) {
  const {
    instrumentId,
    tenor,
    tenorFar,
    date,
    dateFar,
    widget,
    amount,
    fwdCurveVersion,
    isRfq = false,
  } = dtoActionStopSubPayload(payload);

  const dateTenor = prepDateTenor(date, tenor);
  const dateTenorFar = prepDateTenor(dateFar, tenorFar);

  const subscriptionString = `${dateTenor}${dateTenorFar}${amount}${fwdCurveVersion}${isRfq}`;

  // If there is no state to remove return existing
  if (!state[instrumentId] || !state[instrumentId][`${subscriptionString}`])
    return state;

  const subscribersList =
    (state[instrumentId] &&
      state[instrumentId][`${subscriptionString}`] &&
      state[instrumentId][`${subscriptionString}`].subscribers) ||
    [];

  if (subscribersList.length <= 1) {
    // no other subscribers: unsubscribe stream
    stopRfqRfsSubscription(dtoActionStopSubPayload(payload));

    // Copy existing state
    const instrumentSubsCopy = {
      ...state[instrumentId],
    };
    // delete instrument/dateTenor/dateTenorFar combo
    delete instrumentSubsCopy[`${subscriptionString}`];

    if (Object.keys(instrumentSubsCopy).length > 0) {
      // return new state after deletion
      return {
        ...state,
        [instrumentId]: {
          ...instrumentSubsCopy,
        },
      };
    }

    const newState = {
      ...state,
    };
    delete newState[instrumentId];

    return newState;
  }

  // other subscribers: remove subscriber from list
  const newSubscribersList = Array.isArray(subscribersList)
    ? subscribersList.filter((widgetSubscriber) => widgetSubscriber !== widget)
    : [];

  return {
    ...state,
    [instrumentId]: {
      ...state[instrumentId],
      [`${subscriptionString}`]: {
        ...state[instrumentId][`${subscriptionString}`],
        subscribers: newSubscribersList,
      },
    },
  };
}

export function removeRfqRfsSubAll() {
  stopRfqRfsSubscriptionAll();
  return {};
}

export function updateRfqRfsSub(state, payload) {
  if (state && Object.keys(state).length === 0) return state;
  const { instrumentId, rfqSubs } = dtoActionUpdateSubAssetPayload(payload);

  // If there is no state to update return existing
  if (!state[instrumentId]) return state;

  const dateTenors = rfqSubs.reduce(
    (prev, curr) => ({
      ...prev,
      [`${curr.dateTenor}${curr.dateTenorFar}${curr.amount}${curr.fwdCurveVersion}${curr.isRfq}`]:
        {
          ...state[instrumentId][
            `${curr.dateTenor}${curr.dateTenorFar}${curr.amount}${curr.fwdCurveVersion}${curr.isRfq}`
          ],
          clientRequestId: curr.clientRequestId,
        },
    }),
    {}
  );

  return {
    ...state,
    [instrumentId]: {
      ...state[instrumentId],
      ...dateTenors,
    },
  };
}

export const startRfqRfsSubscription = ({
  streamType,
  instrumentId,
  instrumentPair,
  tenor,
  tenorFar,
  date,
  dateFar,
  datePublish,
  clientRequestId,
  amount,
  fwdCurveVersion,
  side,
  isRfq,
  parentInternalRequestId = null,
}) => {
  if (
    instrumentId === 0 ||
    instrumentPair === 'UNSELECTED' ||
    window?.socket?.readyState !== 1
  ) {
    return;
  }

  window.socket.send(
    JSON.stringify({
      body: {
        msg_type: 'start-rfq-instrument-subscription',
        data: {
          streamType: streamType === 'nds' ? 'swap' : streamType,
          instrumentId,
          instrumentPair,
          tenor,
          tenorFar,
          date,
          dateFar,
          datePublish: isValid(datePublish)
            ? format(datePublish, 'yyyy-MM-dd')
            : '',
          clientRequestId,
          amount,
          fwdCurveVersion,
          side,
          isRfq,
          parent_internal_request_id: parentInternalRequestId,
        },
      },
    })
  );
};

export const startRfqRfsSubscriptionAll = (subscriptions) => {
  if (window?.socket?.readyState !== 1) return;

  window.socket.send(
    JSON.stringify({
      body: {
        msg_type: 'start-all-rfq-instrument-subscription',
        data: subscriptions.map((subscription) => ({
          streamType:
            typeof subscription.rfsType === 'string' &&
            subscription.rfsType !== ''
              ? subscription.rfsType.toLowerCase()
              : 'outright',
          clientRequestId: subscription.clientRequestId,
          instrumentId: Number(subscription.instrumentId),
          instrumentPair: subscription.instrumentPair,
          date: subscription.date,
          dateFar: subscription.dateFar,
          tenor: subscription.tenor,
          tenorFar: subscription.tenorFar,
          widget: subscription.widget,
          amount: subscription.amount,
          fwdCurveVersion: subscription.fwdCurveVersion,
        })),
      },
    })
  );
};

export const stopRfqRfsSubscription = ({
  streamType,
  instrumentId,
  instrumentPair,
  tenor,
  tenorFar,
  date,
  dateFar,
  clientRequestId,
  amount,
  fwdCurveVersion,
}) => {
  if (
    instrumentId === 0 ||
    instrumentPair === 'UNSELECTED' ||
    window?.socket?.readyState !== 1
  ) {
    return;
  }

  window.socket.send(
    JSON.stringify({
      body: {
        msg_type: 'stop-rfq-instrument-subscription',
        data: {
          streamType,
          instrumentId,
          instrumentPair,
          tenor,
          tenorFar,
          date,
          dateFar,
          clientRequestId,
          amount,
          fwdCurveVersion,
        },
      },
    })
  );
};

export const stopRfqRfsSubscriptionAll = () => {
  if (window?.socket?.readyState !== 1) return;

  window.socket.send(
    JSON.stringify({
      body: {
        msg_type: 'stop-all-rfq-instrument-subscription',
      },
    })
  );
};

export default function subscriptionsRfqRfsReducer(state = {}, action) {
  const { payload } = action;

  switch (action.type) {
    case REMOVE_RFQ_RFS_SUB:
      return removeRfqRfsSub(state, payload);
    case REMOVE_RFQ_RFS_SUB_ALL:
      return removeRfqRfsSubAll(state, payload);
    case START_RFQ_RFS_SUB: {
      return startRfqRfsSub(state, payload);
    }
    case START_RFQ_RFS_SUB_ALL:
      return startRfqRfsSubAll(state, payload);
    case UPDATE_ASSET_RFQ:
      return updateRfqRfsSub(state, payload);
    default:
      return state;
  }
}
